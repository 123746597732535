import axios from "axios";
import { baseUrl } from "../API";
import { Types } from "../types";

export const getProductList = () => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_REQUEST });
    axios
      .get(baseUrl + "/cpe/getProductByCustomer")
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({ type: Types.PRODUCT_SUCCESS, payload: resp.data.body });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_ERROR });
      });
  };
};

export const productAdd = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_ADD_REQUEST });
    return axios
      .post(baseUrl + "/cpe/addProduct", data)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: Types.ALERT_SUCCESS,
            payload: {
              message: `${data.productName} ${data.productId ? "Updated" : "Added"
                }`,
            },
          });
          dispatch({ type: Types.PRODUCT_ADD_SUCCESS });
        } else if (resp.data?.message) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: resp.data.message },
          });
          dispatch({ type: Types.PRODUCT_ADD_ERROR });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Add Failed" },
          });
          dispatch({ type: Types.PRODUCT_ADD_ERROR });
        }
        return resp;
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_ADD_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Add Failed" },
          });
        }
        return err;
      });
  };
};

export const productAddMultiple = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_ADD_REQUEST });
    // return axios.post(baseUrl + '/cpe/addProduct', data).then(resp => {
    //     if (resp.status === 200) {
    //         dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.productName} ${data.productId ? 'Updated' : 'Added'}` } });
    //         dispatch({ type: Types.PRODUCT_ADD_SUCCESS });
    //     } else if (resp.data?.message) {
    //         dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
    //         dispatch({ type: Types.USER_ADD_ERROR });
    //     } else {
    //         dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Product Add Failed' } });
    //         dispatch({ type: Types.PRODUCT_ADD_ERROR });
    //     }
    //     return resp;
    // })

    // let myData = {
    //     productId: data.id ?? 0,
    //     productName: data.productName,
    //     category: data.category,
    //     quantity: data.quantity,
    //     price: data.price,
    // }
    // axios.all(
    //     data.stores.map(v => {
    //         myData['storeId'] = v.id;
    //         axios.post(baseUrl + '/cpe/addProduct', data)
    //     })
    // ).then(axios.spread((...responses) => {
    // })).catch(err => {
    //     dispatch({ type: Types.PRODUCT_ADD_ERROR });
    //     if (err.response?.data?.error) {
    //         dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
    //     } else {
    //         dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Product Add Failed' } });
    //     }
    //     return err;
    // });

    let promiseArray = data.stores.map((v) =>
      axios.post(baseUrl + "/cpe/addProduct", {
        ...data,
        storeId: v.id,
        stores: null,
      })
    );
    return Promise.all(promiseArray)
      .then((results) => {
        if (results && results.length > 0) {
          // let success = '';
          // let succ = false;
          // let failed = '';
          // let fail = false;

          // toaster
          // results.map((resp, i) => {
          //     if (resp.data.statusCodeValue === 200) {
          //         // success += `${data.stores[i].name} Added ${i + 1 === results.length ? '' : ' & '}`;
          //         // succ = true;
          //         dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.productName} and ${data.stores[i].name} Added` } });
          //     } else {
          //         // failed += `${data.stores[i].name} - ${resp.data.body} ${i + 1 === results.length ? '' : ' & '}`;
          //         // fail = true;
          //         dispatch({ type: Types.ALERT_ERROR, payload: { message: `${data.productName} and ${data.stores[i].name} - ${resp.data.body}`, duration: 10000 } });
          //     }
          // });
          results.map((resp, i) => {
            if (resp.data.statusCodeValue === 200) {
              setTimeout(() => {
                dispatch({
                  type: Types.ALERT_SUCCESS,
                  payload: {
                    message: `${data.stores[i].name} - ${resp.data?.body}`,
                  },
                });
              }, i * 1000);
              // dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.productName} and ${data.stores[i].name} Added` } });
            } else {
              setTimeout(() => {
                dispatch({
                  type: Types.ALERT_ERROR,
                  payload: {
                    message: `${data.stores[i].name} - ${resp.data?.body}`,
                  },
                });
              }, i * 1000);
              // dispatch({ type: Types.ALERT_ERROR, payload: { message: `${data.productName} and ${data.stores[i].name} - ${resp.data.body}`, duration: 10000 } });
            }
            return resp;
          });
          // dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.productName} Added` } });
          // if (fail && succ) {
          //     dispatch({ type: Types.ALERT_WARNING, payload: { message: `${success} & ${failed}` } });
          // } else if (fail) {
          //     dispatch({ type: Types.ALERT_ERROR, payload: { message: `${failed}`, duration: 8000 } });
          // } else {
          //     dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${success}` } });
          // }
          dispatch({ type: Types.PRODUCT_ADD_SUCCESS });
        }
        return results[0];
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_ADD_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Add Failed" },
          });
        }
        return err;
      });
  };
};

export const productEditMultiple = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_ADD_REQUEST });

    let promiseArray = data.map((v) =>
      axios.post(baseUrl + "/cpe/addProduct", { ...v })
    );
    return Promise.all(promiseArray)
      .then((results) => {
        if (results && results.length > 0) {
          results.map((resp, i) => {
            if (resp.data.statusCodeValue === 200) {
              setTimeout(() => {
                dispatch({
                  type: Types.ALERT_SUCCESS,
                  payload: {
                    message: `${data[i].storeName} - ${resp.data?.body}`,
                  },
                });
              }, i * 1000);
              // dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.productName} and ${data.stores[i].name} Added` } });
            } else {
              setTimeout(() => {
                dispatch({
                  type: Types.ALERT_ERROR,
                  payload: {
                    message: `${data[i].storeName} - ${resp.data?.body?.message}`,
                  },
                });
              }, i * 1000);
              // dispatch({ type: Types.ALERT_ERROR, payload: { message: `${data.productName} and ${data.stores[i].name} - ${resp.data.body}`, duration: 10000 } });
            }
            return resp;
          });
          // dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Produts Updated` } });
          dispatch({ type: Types.PRODUCT_ADD_SUCCESS });
        }
        return results[0];
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_ADD_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Edit Failed" },
          });
        }
        return err;
      });
  };
};

export const productDelete = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_DELETE_REQUEST });
    return axios
      .delete(baseUrl + "/cpe/deleteProduct", { data })
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: Types.ALERT_WARNING,
            payload: { message: `${data.productName} Deleted`, duration: 2000 },
          });
          dispatch({ type: Types.PRODUCT_DELETE_SUCCESS });
        } else if (resp.data?.message) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: resp.data.message },
          });
          dispatch({ type: Types.PRODUCT_DELETE_ERROR });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Delete Failed" },
          });
          dispatch({ type: Types.PRODUCT_DELETE_ERROR });
        }
        return resp;
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_DELETE_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Delete Failed" },
          });
        }
        return err;
      });
  };
};

export const productMultipleDelete = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_DELETE_REQUEST });

    let promiseArray = data.map((v) => {
      delete v.values;
      delete v.storeName;
      return axios.delete(baseUrl + "/cpe/deleteProduct", { data: v });
    });
    return Promise.all(promiseArray)
      .then((results) => {
        if (results && results.length > 0) {
          dispatch({
            type: Types.ALERT_SUCCESS,
            payload: { message: `Products Deleted` },
          });
          dispatch({ type: Types.PRODUCT_DELETE_SUCCESS });
        }
        return results[0];
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_DELETE_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Delete Failed" },
          });
        }
        return err;
      });
  };
};

export const productUpload = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_ADD_REQUEST });
    return axios
      .post(baseUrl + "/cpe/addProductWithCsv", data)
      .then((resp) => {
        if (resp.status === 200) {
          if (resp.data && resp.data.response) {
            resp.data.response?.reverse().map((rp, i) => {
              if (rp?.messageType) {
                setTimeout(() => {
                  dispatch({
                    type: Types.ALERT_SUCCESS,
                    payload: {
                      message: `${rp?.validationMessage}`,
                      duration: 5000,
                    },
                  });
                }, i * 700);
              } else {
                setTimeout(() => {
                  dispatch({
                    type: Types.ALERT_ERROR,
                    payload: {
                      message: `${rp?.validationMessage}`,
                      duration: 5000,
                    },
                  });
                }, i * 700);
              }
              return rp;
            });
          } else {
            dispatch({
              type: Types.ALERT_SUCCESS,
              payload: { message: `${resp.data?.message}`, duration: 6000 },
            });
          }
          dispatch({ type: Types.PRODUCT_ADD_SUCCESS });
        } else if (resp.data?.message) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: resp.data.message },
          });
          dispatch({ type: Types.PRODUCT_ADD_ERROR });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Upload Failed" },
          });
          dispatch({ type: Types.PRODUCT_ADD_ERROR });
        }
        return resp;
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_ADD_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Upload Failed" },
          });
        }
        return err;
      });
  };
};

export const productExport = () => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_EXPORT_REQUEST });
    return axios
      .post(baseUrl + "/cpe/exportProductWithCsv")
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: Types.ALERT_SUCCESS,
            payload: { message: `Report Generated`, duration: 500 },
          });
          dispatch({ type: Types.PRODUCT_EXPORT_SUCCESS });
        } else if (resp.data?.message) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: resp.data.message },
          });
          dispatch({ type: Types.PRODUCT_EXPORT_ERROR });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Export Failed" },
          });
          dispatch({ type: Types.PRODUCT_EXPORT_ERROR });
        }
        return resp;
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_EXPORT_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Product Export Failed" },
          });
        }
        return err;
      });
  };
};

export const getProductBindingList = () => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_BIND_LIST_REQUEST });
    axios
      .get(baseUrl + "/cpe/getAllMapProductByCustomer")
      .then((resp) => {
        // axios.get(baseUrl + '/cpe/getAllocatedTemplateByCustomer').then(resp => {
        if (resp.status === 200) {
          dispatch({
            type: Types.PRODUCT_BIND_LIST_SUCCESS,
            payload: resp.data,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_BIND_LIST_ERROR });
      });
  };
};

export const productBind = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_BIND_REQUEST });
    let promiseArray = data.map((v, i) =>
      axios.post(baseUrl + "/cpe/createMapProductTag", {
        ...v,
        position: i + 1,
      })
    );
    return Promise.all(promiseArray)
      .then((results) => {
        if (results && results.length > 0) {
          results.map((resp, i) => {
            console.log("res ", resp);
            if (resp.status === 200) {
              setTimeout(() => {
                dispatch({
                  type: Types.ALERT_SUCCESS,
                  payload: {
                    message: `Template Binding ${data.productTagMapId ? "Updated" : "Success"
                      }`,
                  },
                });
                dispatch({ type: Types.PRODUCT_BIND_SUCCESS });
              }, i * 1000);
            } else {
              setTimeout(() => {
                dispatch({
                  type: Types.ALERT_ERROR,
                  payload: { message: `Template Binding Failed 1` },
                });
                dispatch({ type: Types.PRODUCT_BIND_ERROR });
              }, i * 1000);
            }
            return resp;
          });
          return results[0];
        }
      })
      .catch((err) => {
        dispatch({
          type: Types.ALERT_ERROR,
          payload: { message: `Template Binding Failed 2` },
        });
        dispatch({ type: Types.PRODUCT_BIND_ERROR });
        return err;
      });
  };
};

export const bindDelete = (data) => {
  return (dispatch) => {
    let promiseArray = data.map((v) =>
      axios.delete(baseUrl + "/cpe/deleteMapProduct/" + v.productTagMapId)
    );

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("results ", results);
        // return axios.delete(baseUrl + '/cpe/deleteMapProduct/' + id).then(resp => {
        if (results && results.length > 0) {
          dispatch({
            type: Types.ALERT_WARNING,
            payload: { message: `Template Deleted`, duration: 2000 },
          });
          dispatch({ type: Types.PRODUCT_BIND_DELETE_SUCCESS });
        }
        // if (resp.status === 200) {
        //     dispatch({ type: Types.ALERT_WARNING, payload: { message: `Template Deleted`, duration: 2000 } });
        //     dispatch({ type: Types.PRODUCT_BIND_DELETE_SUCCESS });
        // } else if (resp.data?.message) {
        //     dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
        //     dispatch({ type: Types.PRODUCT_BIND_DELETE_ERROR });
        // } else {
        //     dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Delete Failed' } });
        //     dispatch({ type: Types.PRODUCT_BIND_DELETE_ERROR });
        // }
        return results[0];
      })
      .catch((err) => {
        dispatch({ type: Types.PRODUCT_BIND_DELETE_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: "Delete Failed" },
          });
        }
        return err;
      });
  };
};

export const pushImages = (id) => {
  return (dispatch) => {
    dispatch({ type: Types.PUSH_IMAGE_REQUEST });
    return axios
      .get(baseUrl + "/cpe/authenticate/list/postImage/" + id)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: Types.ALERT_SUCCESS,
            payload: { message: `Push Image Success`, duration: 2000 },
          });
          dispatch({ type: Types.PUSH_IMAGE_SUCCESS });
        } else if (resp.data?.message) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: resp.data.message },
          });
          dispatch({ type: Types.PUSH_IMAGE_ERROR });
        } else {
          dispatch({ type: Types.ALERT_ERROR, payload: { message: "Failed" } });
          dispatch({ type: Types.PUSH_IMAGE_ERROR });
        }
        return resp;
      })
      .catch((err) => {
        dispatch({ type: Types.PUSH_IMAGE_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({ type: Types.ALERT_ERROR, payload: { message: "Failed" } });
        }
        return err;
      });
  };
};

export const makeImage = (tagId, productImg, isLater = false, date) => {
  return (dispatch) => {
    dispatch({ type: Types.PUSH_IMAGE_REQUEST });
    const url = isLater
      ? `/cpe/image/later/saveImage?tagId=${tagId}&epochTime=${date}`
      : `/cpe/authenticate/makeImage?tagId=${tagId}`;
    return axios
      .post(baseUrl + url, productImg, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: Types.ALERT_SUCCESS,
            payload: { message: `Push Image Success`, duration: 2000 },
          });
          dispatch({ type: Types.PUSH_IMAGE_SUCCESS });
        } else if (resp.data?.message) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: resp.data.message },
          });
          dispatch({ type: Types.PUSH_IMAGE_ERROR });
        } else {
          dispatch({ type: Types.ALERT_ERROR, payload: { message: "Failed" } });
          dispatch({ type: Types.PUSH_IMAGE_ERROR });
        }
        return resp;
      })
      .catch((err) => {
        dispatch({ type: Types.PUSH_IMAGE_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({ type: Types.ALERT_ERROR, payload: { message: "Failed" } });
        }
        return err;
      });
  };
};

export const generateImage = (tagId, productImg, isLater = false, date) => {
  return (dispatch) => {
    dispatch({ type: Types.PUSH_IMAGE_REQUEST });
    const url = isLater
      ? `/cpe/image/later/saveImage?tagId=${tagId}&epochTime=${date}`
      : `/cpe/authenticate/generateImage?tagId=${tagId}`;

    const contentType = !productImg ? "application/json" : "multipart/form-data"
    return axios
      .post(baseUrl + url, productImg, {
        headers: {
          "content-type": contentType,
        },
      })
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: Types.ALERT_SUCCESS,
            payload: { message: `Push Image Success`, duration: 2000 },
          });
          dispatch({ type: Types.PUSH_IMAGE_SUCCESS });
        } else if (resp.data?.message) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: resp.data.message },
          });
          dispatch({ type: Types.PUSH_IMAGE_ERROR });
        } else {
          dispatch({ type: Types.ALERT_ERROR, payload: { message: "Failed" } });
          dispatch({ type: Types.PUSH_IMAGE_ERROR });
        }
        return resp;
      })
      .catch((err) => {
        dispatch({ type: Types.PUSH_IMAGE_ERROR });
        if (err.response?.data?.error) {
          dispatch({
            type: Types.ALERT_ERROR,
            payload: { message: err.response?.data?.error },
          });
        } else {
          dispatch({ type: Types.ALERT_ERROR, payload: { message: "Failed" } });
        }
        return err;
      });
  };
};

export const getImageInfos = (data) => {
  return (dispatch) => {
    dispatch({ type: Types.PRODUCT_BIND_DELETE_REQUEST });
    let promiseArray = data.map((v) =>
      axios.get(baseUrl + "/cpe/getImageInfo/" + v.id)
    );

    return Promise.all(promiseArray)
      .then((results) => {
        console.log("results ", results);

        return results;
      })
      .catch((err) => {
        // dispatch({ type: Types.PRODUCT_BIND_DELETE_ERROR });
        // if (err.response?.data?.error) {
        //     dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
        // } else {
        //     dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Delete Failed' } });
        // }
        // return err;
        console.log(err);
      });
  };
};

export const getTemplatePreview = (body) => {
  return (dispatch) => {
    dispatch({ type: Types.TEMPLATE_PREVIEW_REQUEST });
    return axios
      .post(baseUrl + "/cpe/authenticate/updateTag", body)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch({
            type: Types.TEMPLATE_PREVIEW_SUCCESS,
            payload: resp.data,
          });
        }
        return resp;
      })
      .catch((err) => {
        dispatch({ type: Types.TEMPLATE_PREVIEW_ERROR });
        return err;
      });
  };
};
