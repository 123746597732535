import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CustomModal, ModalList } from '../../components/form/modal';
import { Loader } from '../../components/utilities/loader';
import DataTable from '../../components/utilities/table'
import { GetAllCpeList } from '../../store/devices/deviceService';

export default function ListDevice() {

    const dispatch = useDispatch();

    const [state, setState] = useState({ data: [] });
    const [modal, setModal] = useState({ viewData: {}, viewModal: false });
    // const [modal, setModal] = useState({ enableModal: false, enableData: {} });

    const { loading, cpeData } = useSelector(state => state.cpeList);

    useEffect(() => {
        dispatch(GetAllCpeList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = cpeData.map((val, i) => {
            return {
                no: i + 1,
                serialNumber: val.serialNum,
                modalName: val.name,
                configuration: val.config,
                storeName: val.storeName,
                id: val.storeId,
                ipAddress: val.ipAddress,
                macAddress: val.macAddress
            }
        });
        setState(state => ({ ...state, data: data }))
    }, [cpeData]);

    const header = [
        { headerName: '#', field: 'no', editable: false, resizable: true, maxWidth: 70 },
        { headerName: 'Device Tag ID', field: 'serialNumber', editable: false, resizable: true },
        { headerName: 'Model Name', field: 'modalName', maxWidth: 150, editable: false, resizable: true },
        // { headerName: 'Configuration', field: 'configuration', editable: false, resizable: true },
        // { headerName: 'Host Name', field: 'hostname', editable: false, resizable: true },
        { headerName: 'IP Address', field: 'ipAddress', editable: false, resizable: true },
        { headerName: 'MAC Address', field: 'macAddress', editable: false, resizable: true },
        // { headerName: 'Store', field: 'storeName', editable: false, resizable: true },
        {
            headerName: 'Action', editable: false, minWidth: 200, cellRendererFramework: (param) => {
                return (
                    <React.Fragment>
                        {/* <i className='fa fa-info-circle text-theme icon-padding icon16' onClick={() => viewHandle(param.data)} /> */}
                        <img className='pointer icon-padding' src={'/img/info.png'} alt={'info'} onClick={() => viewHandle(param.data)} width={22} />

                    </React.Fragment>
                )
            }
        },
    ];

    const viewHandle = (data) => {
        setModal(modal => ({ ...modal, viewData: data, viewModal: true }));
    }

    const closeHandle = () => {
        setModal(modal => ({ ...modal, viewData: {}, viewModal: false }));
    }

    return (
        <div>
            <div className="row d-flex align-items-center mb-3 mb-md-2">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">Base Station {loading ? <Loader /> : null}</p>
                </div>
            </div>
            <div className="row d-flex justify-content-end">
                <DataTable key={state.data} headers={header} data={state.data} pagination={true} globalSearch={true} />
            </div>

            {modal.viewModal ?
                <CustomModal onclose={closeHandle}>
                    <div className="row d-flex align-items-center mb-3 mb-md-5">
                        <div className="col-md-16">
                            <p className="medium ft-23 mb-2 mb-md-0" style={{ marginLeft: '2rem' }}>Base Station Detail</p>
                        </div>
                        <div className="col-md-6 text-start text-md-end">
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row">
                                <ModalList title='Serial Number' value={modal.viewData?.serialNumber} size={12} />
                                <ModalList title='Model Name' value={modal.viewData?.modalName} size={12} />
                                {/* <ModalList title='Host Name' value={modal.viewData?.hostname} /> */}
                                <ModalList title='IP Address' value={modal.viewData?.ipAddress} />
                                <ModalList title='MAC Address' value={modal.viewData?.macAddress} />
                                {/* <ModalList title='Store' value={modal.viewData?.storeName} /> */}
                            </div>
                        </div>
                    </div>
                </CustomModal> : null}

        </div>

    )
}
