import axios from "axios";
import { conf } from "./conf";
import { baseUrl } from "./store/API";
import { useEffect, useState } from "react";
import v from './version.json';

export const APP_VERSION = () => {
  const [beVersion, setBeVersion] = useState('');

  const getVersion = () => {
    axios.get(baseUrl + '/authenticate/getVersion').then(resp => {
      if (resp.status === 200) {
        setBeVersion(resp.data);
        // dispatch({ type: Types.ESL_SUCCESS, payload: resp.data });
      }
    });
  }
  useEffect(() => {
    getVersion();
  }, []);
  // console.log();
  //get text from file

  return (
    <span style={{ fontSize: "12px" }} >{conf.branch} | ui v{v.version} | api v{beVersion}</span >
  )
};
