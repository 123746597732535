// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { UserLogin } from "../../store/login/loginService";
// import { RootState } from '../../store/rootReducer';

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { DashboardInfo, Navigation } from "../../store/common/commonService";
import { Loader } from "../../components/utilities/loader";
import { conf } from "../../conf";

function Dashboard() {
    const dispatch = useDispatch();
    const { dashboardLoading, dashboardData } = useSelector((state) => state.dashboard);

    useEffect(() => {
        dispatch(DashboardInfo());
    }, []);

    return (
        <React.Fragment>
            <p className="medium ft-23 mb-4">Dashboard {dashboardLoading ? <Loader /> : null}</p>
            <div className="row">
                <div className="col-md-6 col-lg-3">
                    <Link to="/users-list" onClick={() => dispatch(Navigation('userList'))}>
                        <div className="dashboard-box">
                            <p className="medium ft-22 mb-2">Total Users</p>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-people me-1" viewBox="0 0 16 16">
                                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                </svg> <div className="medium d-inline-block">{dashboardData?.totalUser}</div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-lg-3">
                    <Link to="/store-list" onClick={() => dispatch(Navigation('storeList'))}>
                        <div className="dashboard-box">
                            <p className="medium ft-22 mb-2">Total Sites</p>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} fill="currentColor" className="bi bi-shop-window me-2" viewBox="0 0 16 16">
                                    <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zm2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5z" />
                                </svg> <div className="medium d-inline-block">{dashboardData?.totalStores}</div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-lg-3">
                    <Link to="/base-station" onClick={() => dispatch(Navigation('baseStation'))}>
                        <div className="dashboard-box">
                            <p className="medium ft-22 mb-2">Total Basestations</p>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-laptop me-2" viewBox="0 0 16 16">
                                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                                </svg> <div className="medium d-inline-block">{dashboardData?.totalCpeDevices}</div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-6 col-lg-3">
                    <Link to="/esl-tag-list" onClick={() => dispatch(Navigation('eslTagList'))}>
                        <div className="dashboard-box">
                            <p className="medium ft-22 mb-2">{conf.dash.totalTags}</p>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width={44} height={44} fill="currentColor" className="bi bi-bookmarks " viewBox="0 0 16 16">
                                    <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z" />
                                    <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z" />
                                </svg> <div className="medium d-inline-block">{dashboardData?.totalEslTags}</div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
