import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomModal, ModalList } from '../../components/form/modal';
import { CSVLink } from "react-csv";
import { Loader } from '../../components/utilities/loader';
import DataTable from '../../components/utilities/table'
import { Navigation } from '../../store/common/commonService';
import { AutoScanEsl, deleteESLTags, eslTagsExport, eslUpload, getESLList } from '../../store/esltags/eslService';
import { getStoreList } from '../../store/store/storeService';
import { Types } from '../../store/types';
import { conf } from "../../conf";

let mappedProduct = 0;
export default function EslTagsList() {

    const dispatch = useDispatch();
    const fileRef = useRef(null);

    const [state, setState] = useState({ data: [], all: false, storeOptions: [], clearSearch: false, });
    const [checkbox, setCheckBox] = useState([]);
    const [exports, setExports] = useState({ isExport: false, data: '' });
    const [modal, setModal] = useState({ enableModal: false, enableData: {}, viewData: {}, viewModal: false, bulkModal: false, deleteModal: false, deleteData: {} });
    const [file, setFile] = useState({ modal: false, file: null });
    const [deleteAllPop, setDeleteAllPop] = useState(false)
    const { storeData } = useSelector(state => state.stores);

    const { loading, eslData, deleteEslLoad } = useSelector(state => state.eslList);

    useEffect(() => {
        dispatch(getESLList());
        dispatch(getStoreList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = eslData.map((val, i) => {
            if (val.isMappedWithProduct) mappedProduct = + 1;
            return {
                no: i + 1,
                serialNumber: val.serialNo,
                screenSize: val.screenSize,
                store: val.storeName,
                id: val.tagId,
                isMappedWithProduct: val.isMappedWithProduct
            }
        });
        setState(state => ({ ...state, data: data }))
    }, [eslData]);

    useEffect(() => {
        if (storeData) {
            const data = storeData.map((v) => ({
                key: v.storeId,
                name: v.storeName
            }));
            setState(state => ({ ...state, storeOptions: data }));
        }
    }, [storeData])

    const header = [
        {
            headerName: '#', editable: false, resizable: true, maxWidth: 100, cellRendererFramework: (param) => {
                return (
                    <React.Fragment>
                        {param.data.isMappedWithProduct ?
                            <><label className="form-check-label">
                                {param.data.no}
                            </label></> :
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" style={{ marginTop: "0.8rem" }} type="checkbox" onChange={() => handleClick(param.data.id)} checked={checkbox.includes(param.data.id) ? true : false} />
                                <label className="form-check-label">
                                    {param.data.no}
                                </label>
                            </div>}
                    </React.Fragment>
                )
            }
        },
        { headerName: 'Serial Number', field: 'serialNumber', editable: false, resizable: true },
        { headerName: 'Screen Size', field: 'screenSize', maxWidth: 150, editable: false, resizable: true },
        { headerName: 'Site', field: 'store', editable: false, resizable: true },
        {
            headerName: 'Action', editable: false, minWidth: 200, cellRendererFramework: (param) => {
                return (
                    <React.Fragment>
                        {/* <i className='fa fa-info-circle text-primary icon-padding icon16' onClick={() => viewHandle(param.data)} /> */}
                        <img className='pointer icon-padding' src={'/img/info.png'} alt={'info'} onClick={() => viewHandle(param.data)} width={22} />
                        <Link to={'/esl-tag-edit/' + param.data.id} ><img className='pointer icon-padding' src={'/img/edit.png'} alt={'edit'} width={22} /></Link>
                        {!param.data.isMappedWithProduct ? <img className='pointer icon-padding' src={'/img/delete.png'} alt={'info'} onClick={() => deleteModal(param.data)} width={20} /> : <></>}

                        {/* {param.data.isMappedWithProduct ? <></> : <i className='fa fa-trash text-danger icon-padding' onClick={() => deleteModal(param.data)} />} */}
                        {/* <button className={`btn btn-sm ${param.data.status ? 'btn-danger' : 'btn-theme'}`} onClick={() => deleteModal(param.data)}>{param.data.status ? <> <i className='fa fa-times' /> Disable </> : <> <i className='fa fa-check' /> Enable</>}</button> */}
                    </React.Fragment>
                )
            }
        },
    ];

    const handleClick = (id) => {
        if (checkbox.includes(id)) {
            const checkboxItems = JSON.parse(JSON.stringify(checkbox));
            const check = checkboxItems.findIndex(o => o === id);
            checkboxItems.splice(check, 1);
            setCheckBox(checkboxItems);
        } else {
            setCheckBox([...checkbox, id])
        }
    }

    const handleAllClick = (id) => {
        if (!state.all) {
            const data = state.data.filter(o => !o.isMappedWithProduct).map(i => i.id)
            setCheckBox(data);
            setState(state => ({ ...state, all: true }));
        } else {
            setCheckBox([]);
            setState(state => ({ ...state, all: false }));
        }
    }

    const deleteModal = (data) => {
        setModal(modal => ({ ...modal, deleteModal: true, deleteData: data }));
    }

    const exportHandle = () => {
        dispatch(eslTagsExport()).then(resp => {
            if (resp.status === 200) {
                //     dispatch(getProductList());
                //     setFile(file => ({ ...file, modal: false, file: null }));
                setExports(exports => ({ ...exports, isExport: true, data: `${resp.data}` }));
            }
        });
    }

    const uploadFile = () => {
        fileRef.current.click();
    }

    const closeCSV = () => {
        setTimeout(() => {
            setExports(exports => ({ ...exports, isExport: false, data: `` }));
        }, 3000);
    }

    const viewHandle = (data) => {
        setModal(modal => ({ ...modal, viewData: data, viewModal: true }));
    }

    const closeHandle = () => {
        setModal(modal => ({ ...modal, enableModal: false, enableData: {}, viewData: {}, viewModal: false, bulkModal: false, deleteModal: false, deleteData: {} }));
    }
    const closeDeleteAllpop = () => {
        setDeleteAllPop(false)
    }

    const fileUploadModal = (e) => {
        let files = e.target.files[0];
        if (files.type === 'text/csv') {
            setFile(file => ({ ...file, modal: true, file: files }));
            setState(state => ({ ...state, clearSearch: true }));
            setTimeout(() => {
                setState(state => ({ ...state, clearSearch: false }));
            }, 1000);
        } else {
            dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Invalid Format. Upload CSV File.' } });

        }
    }

    const fileUpload = () => {
        const formData = new FormData();
        formData.append('file', file.file);
        dispatch(eslUpload(formData)).then(resp => {
            if (resp.status === 200) {
                dispatch(getESLList());
                setFile(file => ({ ...file, modal: false, file: null }));
                // setState(state => ({ ...state, storeSelected: [] }))
            }
        });
    }


    const closeFile = () => {
        setFile(file => ({ ...file, modal: false, file: null }));
    }

    const deleteTags = (id) => {
        // deleteESLTags(id);
        dispatch(deleteESLTags(id)).then(resp => {
            if (resp.status === 200) {
                closeHandle();
                closeDeleteAllpop();
                setCheckBox([]);
            }
            dispatch(getESLList());
        });
    }

    const autoScan = () => {
        dispatch(AutoScanEsl({})).then(resp => {
            if (resp.status === 200) {
            }
        });
    }

    return (
        <div>
            <div className="row d-flex align-items-center mb-3 mb-md-2">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">{conf.esltag.list} {loading ? <Loader /> : null}</p>
                </div>
                <div className="col-md-6 text-start text-md-end">
                    <button className='btn btn-warning' onClick={() => autoScan()}>{conf.esltag.autoScan}</button>
                    {/* {AdminRoles.includes(userType) ? */}
                    <Fragment>
                        {exports.isExport ? <CSVLink data={exports.data} onClick={() => closeCSV()} filename={"esltags_list.csv"} className='btn btn-theme iconBtn'>
                            <i className='fa fa-download' style={{ paddingTop: '6px' }} />
                            <span className='iconText'> Download CSV</span></CSVLink> : null}
                        {conf.esltag.upload &&
                            <button className='btn iconBtn' style={{ backgroundColor: '#b18e2b', color: 'white' }} onClick={() => uploadFile()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                </svg> <span className='iconText'>Upload</span>
                            </button>
                        }
                        {/* <button className='btn iconBtn' style={{ backgroundColor: '#779535', color: 'white' }} onClick={exportHandle}>
                            {eslExportLoading ? <Loader /> : <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                </svg>
                            </>}
                            <span className='iconText'> Download</span></button> */}
                        <Link className="btn btn-theme" to="/esl-tag-add" onClick={() => dispatch(Navigation('eslTagAdd'))}> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg> {conf.esltag.add}</Link>
                    </Fragment>
                    {/* : null} */}
                </div>
                {/* <Link className="btn btn-theme" to="/esl-tag-add" onClick={() => dispatch(Navigation('eslTagAdd'))}> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg> Add ESL Tag</Link> */}
                {/* </div> */}
            </div>
            <div className="row d-flex justify-content-end">
                <DataTable key={state.data} headers={header} data={state.data} pagination={true} globalSearch={true} searchClear={state.clearSearch} >
                    {state.data?.length > 0 ?
                        <span style={{ display: "flex" }}>
                            <div className="form-check">
                                <input className="form-check-input" id={checkbox.length > 0 && (checkbox.length + mappedProduct) < state.data.length ? "flexCheckIndeterminate" : null} type="checkbox" checked={(checkbox.length + mappedProduct) === state.data.length} onChange={() => handleAllClick()} />
                                <label className="form-check-label">
                                    Select All
                                </label>
                            </div>
                            {checkbox.length > 0 ?
                                <button className='btn btn-sm btn-danger mb-2' style={{ marginLeft: '1rem' }} onClick={() => setModal(modal => ({ ...modal, bulkModal: true }))}>Delete</button>
                                : null}
                        </span>
                        : null}
                </DataTable>
            </div>

            <input type='file' name='file' style={{ display: 'none' }} ref={fileRef} onChange={(e) => fileUploadModal(e)} onClick={(e) => e.target.value = ''} />

            {modal.viewModal ?
                <CustomModal onclose={closeHandle}>
                    <div className="row d-flex align-items-center mb-3 mb-md-5">
                        <div className="col-md-12">
                            <p className="medium ft-23 mb-2 mb-md-0" style={{ marginLeft: '2rem' }}>{conf.esltag.detail}</p>
                        </div>
                        <div className="col-md-6 text-start text-md-end">
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row">
                                <ModalList title='Serial Number' value={modal.viewData?.serialNumber} size={12} />
                                <ModalList title='Screen Size' value={modal.viewData?.screenSize} size={12} />
                                <ModalList title='Site' value={modal.viewData?.store} />
                            </div>
                        </div>
                    </div>
                </CustomModal> : null}

            {modal.bulkModal ?
                <CustomModal onclose={closeHandle} size='xl' isLeft={true}>
                    <div className={`row d-flex align-items-center mb-3 mb-md-5`}>
                        <div className="col-md-6">
                            <p className="medium ft-23 mb-2 mb-md-0" style={{ marginLeft: '2rem' }}>Edit Display</p>
                        </div>
                        <div className="col-md-6 text-start text-md-end">
                            Selected Tags: {checkbox.length}
                        </div>
                    </div>
                    <div className={`row d-flex`}>
                        {/* <div className='col-6 mt-5'>
                            <Select label={'Select Store'} options={state.storeOptions} />
                            <div className="mb-5 mt-4 float-right">
                                <span className="btn btn-secondary float-right" onClick={closeHandle}>Close</span>
                            </div>
                        </div> */}
                        <div className={`col-6 col-lg-6 scroll-content-tag w-100`}>
                            <div className="row mx-3">
                                {checkbox.map((v, i) => {
                                    const data = state.data.find(o => o.id === v);
                                    return <span key={i}>{++i}. <b>{data?.serialNumber}</b> ({data?.store})</span>
                                    // return `${++i}. ${data?.serialNumber} (${data?.store}), `
                                })}
                            </div>
                        </div>
                        <div className={"d-flex justify-content-end"}>
                            <button className='btn btn-sm btn-danger mb-2' onClick={() => setDeleteAllPop(true)}>Delete</button>
                        </div>
                    </div>
                </CustomModal> : null}
            {deleteAllPop ?
                <CustomModal onclose={closeDeleteAllpop}>
                    <p className="medium ft-23 mb-1">{`Are you Sure to Delete ${checkbox.length} Items ?`}</p>
                    <div className="text-center mb-5 mt-4">
                        <span className="btn btn-secondary" onClick={closeDeleteAllpop}>Close</span>
                        {/* <span className="btn btn-danger" >Delete</span> */}
                        <span className={`btn btn-danger`} onClick={() => deleteTags(checkbox)}>{deleteEslLoad ? <><Loader /> Loading</> : 'Delete'}</span>
                    </div>
                </CustomModal> : null}
            {modal.deleteModal ?
                <CustomModal onclose={closeHandle}>
                    <p className="medium ft-23 mb-1">{`Are you Sure to Delete ${modal.deleteData?.serialNumber}?`}</p>
                    <div className="text-center mb-5 mt-4">
                        <span className="btn btn-secondary" onClick={closeHandle}>Close</span>
                        <span className={`btn btn-danger`} onClick={() => deleteTags(modal.deleteData?.id)}>{deleteEslLoad ? <><Loader /> Loading</> : 'Delete'}</span>
                        {/* <span className={`btn ${modal.enableData?.status ? 'btn-danger' : 'btn-theme'}`} onClick={statusHandle}>{addLoad ? <><Loader /> Loading</> : modal.enableData?.status ? 'Disable' : 'Enable'}</span> */}
                    </div>
                </CustomModal> : null}

            {file.modal ?
                <CustomModal onclose={closeFile} size={'lg'}>
                    <p className="medium ft-23 mb-1">{`Are you Sure to Upload ${file.file.name}?`}</p><br />

                    <div className="text-center mb-5 mt-4">
                        <span className="btn btn-secondary" onClick={closeFile}>Close</span>
                        <span className="btn btn-theme" onClick={fileUpload}>Submit</span>


                        {/* {state.storeSelected?.length > 0 ? <span className={`btn btn-theme`} onClick={fileUpload}>{addLoad ? <><Loader /> Loading</> : 'Submit (' + state.storeSelected.length + ')'}</span> : null} */}
                    </div>
                </CustomModal> : null}

        </div>

    )
}
