// import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdminRoles } from '../../components/utilities/functions';
import { Navigation } from '../../store/common/commonService';
import { UserLogout } from '../../store/login/loginService';

import { GrSystem } from 'react-icons/gr';
import { conf } from "../../conf";

// import { Loader } from "../../components/utilities/loader";
// import { UserLogin } from "../../store/login/loginService";
// import { RootState } from '../../store/rootReducer';

function Sidebar(props) {
    const { userData } = useSelector((state) => state.login);
    // const { login, alert } = useSelector((state: RootState) => state);
    const dispatch = useDispatch();
    const [state, setState] = useState({ notification: false, profile: false });
    const { navigation } = useSelector((state) => state);

    const notification = useRef(null);
    const profile = useRef(null);

    const logoutHandle = (e) => {
        dispatch(UserLogout())
    }

    const toggleHandle = () => {
        props.toggle();
    }

    const navigationHandle = (menu) => {
        if (window.innerWidth < 982) {
            props.toggle();
        }
        dispatch(Navigation(menu));
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notification.current && !notification.current.contains(event.target)) {
                setState(state => ({ ...state, notification: false }));
            }
            if (profile.current && !profile.current.contains(event.target)) {
                setState(state => ({ ...state, profile: false }));
            }
        }

        const handleEscape = (event) => {
            if (event.keyCode === 27) {
                setState(state => ({ ...state, profile: false, notification: false }));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleEscape);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleEscape);
        };
    }, [notification, profile]);

    const getInitial = () => {
        let initial = 'A';
        if (userData?.userName) {
            initial = userData.userName[0] ? userData.userName[0].toUpperCase() : 'A';
        }
        return initial;
    }

    return (
        <React.Fragment>
            <div className="navbar">
                <div className={props.isToggle ? "topnav" : "topnav open1"}>
                    <div className="menu-toggle" onClick={toggleHandle}>
                        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                        <span style={{ fontSize: '16px', marginLeft: '1rem' }}>{userData?.customerName?.toUpperCase()}</span>
                    </div>
                    <ul className="list-unstyled mb-0">
                        <li className=" dropdown progile-dropdown list-inline-item me-3 position-relative" onClick={() => setState({ ...state, notification: true })}>
                            <Link to="/basestation"><span style={{ fontSize: '16px', marginLeft: '1rem', fontWeight: 'bold' }}>Base Station </span>
                                <GrSystem />
                                {/* <span className={`dropdown-toggle text-muted pr-0 ${state.notification ? 'show' : null}`} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} fill="currentColor" className="bi bi-bell" viewBox="0 0 16 16">
                                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
                                    </svg>
                                    <span className="dot" />
                                </span> */}
                            </Link>
                        </li>
                        <li className="dropdown progile-dropdown list-inline-item" onClick={() => setState({ ...state, profile: true })}>
                            <span className={`dropdown-toggle text-muted pr-0 ${state.profile ? 'show' : null}`} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                <span className="avatar avatar-sm mt-2">
                                    {/* <img src="/img/face-1.jpg" alt="..." className="avatar-img rounded-circle" /> */}
                                    <span className="avatar-img rounded-circle profileIcon">{getInitial()}</span>

                                </span>
                            </span>
                            <div ref={profile} className={`dropdown-menu dropdown-menu-end ${state.profile ? 'show' : null}`} aria-labelledby="navbarDropdownMenuLink" data-bs-popper="none" >
                                {/* <a className="dropdown-item" href="profile.html">Profile</a>
                                <a className="dropdown-item" href="settings.html">Settings</a> */}
                                <span className="dropdown-item logout" onClick={logoutHandle}>Log Out</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={props.isToggle ? "sidebar-left" : "sidebar-left close1"}>
                <div className="sidebar-left-pad 123456">
                    <div className="colse-sidebar" onClick={toggleHandle}> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg></div>
                    <img src="/img/logo.png" className="img-fluid s-logo" alt="img" />
                    <div className="sidebar-ul">
                        <ul className="list-unstyled">
                            <li><Link to="/" className={navigation.menu === 'dashboard' ? "active" : ""} onClick={() => navigationHandle('dashboard')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-house me-2" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
                                <path fillRule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
                            </svg> Dashboard</Link></li>
                        </ul>
                        <p className="op-7 mt-4">User</p>
                        <ul className="list-unstyled">
                            <li><Link to="/users-list" className={navigation.menu === 'userList' ? "active" : ""} onClick={() => navigationHandle('userList')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-people me-2" viewBox="0 0 16 16">
                                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                            </svg> User List</Link></li>
                            {AdminRoles.includes(userData?.userType) ?
                                <li><Link to="/user-add" className={navigation.menu === 'userAdd' ? "active" : ""} onClick={() => navigationHandle('userAdd')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg> Add User</Link></li>
                                : null}
                        </ul>
                        <p className="op-7 mt-4">Sites</p>
                        <ul className="list-unstyled">
                            <li><Link to="/store-list" className={navigation.menu === 'storeList' ? "active" : ""} onClick={() => navigationHandle('storeList')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-shop-window me-2" viewBox="0 0 16 16">
                                <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zm2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5z" />
                            </svg> Site List</Link></li>
                            <li onClick={() => navigationHandle('storeAdd')}><Link to="/store-add" className={navigation.menu === 'storeAdd' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg> Add Site</Link></li>
                        </ul>
                        <p className="op-7 mt-4">Base Station</p>
                        <ul className="list-unstyled">
                            <li><Link to="/base-station" className={navigation.menu === 'baseStation' ? "active" : ""} onClick={() => navigationHandle('baseStation')}><svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-laptop me-2" viewBox="0 0 16 16">
                                <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                            </svg> Base Station</Link></li>
                        </ul>
                        <p className="op-7 mt-4">{conf.esltag.esltags}</p>
                        <ul className="list-unstyled">
                            <li><Link to="/esl-tag-list" className={navigation.menu === 'eslTagList' ? "active" : ""} onClick={() => navigationHandle('eslTagList')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-bookmarks me-2" viewBox="0 0 16 16">
                                <path d="M2 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v11.5a.5.5 0 0 1-.777.416L7 13.101l-4.223 2.815A.5.5 0 0 1 2 15.5V4zm2-1a1 1 0 0 0-1 1v10.566l3.723-2.482a.5.5 0 0 1 .554 0L11 14.566V4a1 1 0 0 0-1-1H4z" />
                                <path d="M4.268 1H12a1 1 0 0 1 1 1v11.768l.223.148A.5.5 0 0 0 14 13.5V2a2 2 0 0 0-2-2H6a2 2 0 0 0-1.732 1z" />
                            </svg> {conf.esltag.list}</Link></li>
                            <li><Link to="/esl-tag-add" className={navigation.menu === 'eslTagAdd' ? "active" : ""} onClick={() => navigationHandle('eslTagAdd')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg> {conf.esltag.add}</Link></li>
                        </ul>

                        <div>
                            <p className="op-7 mt-4">{conf.product.products}</p>
                            <ul className="list-unstyled">
                                <li><Link to="/product-list" className={navigation.menu === 'productList' ? "active" : ""} onClick={() => navigationHandle('productList')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-archive me-2" viewBox="0 0 16 16">
                                    <path d="M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1V2zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5H2zm13-3H1v2h14V2zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                </svg> {conf.product.list}</Link></li>
                                <li><Link to="/product-add" className={navigation.menu === 'addProduct' ? "active" : ""} onClick={() => navigationHandle('addProduct')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg> {conf.product.add}</Link></li>
                            </ul>
                            {/* <p className="op-7 mt-4">SquarePOS</p>
                            <ul className="list-unstyled">
                                <li><Link to="/square-pos" className={navigation.menu === 'squarepos' ? "active" : ""} onClick={() => navigationHandle('squarepos')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg> Square POS</Link></li>
                            </ul> */}


                            {/* <p className="op-7 mt-4">Template</p>
                            <ul className="list-unstyled"> */}
                            {/* <li onClick={() => navigationHandle('favorite')}><Link to="/favorite-template" className={navigation.menu === 'favorite' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-heart me-2" viewBox="0 0 16 16">
                                    <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
                                </svg> Favorite Template</Link></li> */}
                            {/* <li onClick={() => navigationHandle('template')}><Link to="/template-add" className={navigation.menu === 'template' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-plus-lg me-2" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg> Create Template</Link></li> */}
                            {/* </ul> */}
                            <p className="op-7 mt-4">Product Binding</p>
                            <ul className="list-unstyled">

                                {/* <li onClick={() => navigationHandle('library')}><Link to="/template-library-test" className={navigation.menu === 'library' ? "active" : ""}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-card-text me-2" viewBox="0 0 16 16">
                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
                                </svg> Test</Link></li> */}

                                <li><Link to="/template-library" className={navigation.menu === 'library' ? "active" : ""} onClick={() => navigationHandle('library')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-card-text me-2" viewBox="0 0 16 16">
                                    <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                                    <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
                                </svg> Template Library</Link></li>
                                <li><Link to="/product-binding-list" className={navigation.menu === 'bindList' ? "active" : ""} onClick={() => navigationHandle('bindList')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-box-seam me-2" viewBox="0 0 16 16">
                                    <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                                </svg> {conf.binding.bindingList}</Link></li>
                                <li><Link to="/product-binding" className={navigation.menu === 'binding' ? "active" : ""} onClick={() => navigationHandle('binding')}><svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="currentColor" className="bi bi-box-seam me-2" viewBox="0 0 16 16">
                                    <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                                </svg> {conf.binding.binding}</Link></li>
                            </ul>
                        </div>


                    </div>
                    <div className="text-center">
                        <button className="btn btn-theme" onClick={logoutHandle} > <img src="/img/icon/logout.png" className="img-fluid me-2" alt="img" />Logout</button>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default Sidebar;
