export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(/^(?:\w+[\-\.])*\w+@(?:\w+[\-\.])*\w+\.\w+$/);
  // return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/);
  // return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/);
};

export const Roles = ["CUSTOMER_ADMIN", "CUSTOMER_STAFF"];

export const validateURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const RoleOptions = [
  { key: "CUSTOMER_ADMIN", name: "ADMIN" },
  { key: "CUSTOMER_STAFF", name: "STAFF" },
  // { key: 'SUPER_ADMIN', name: 'SUPER ADMIN' },
];

export const AdminRoles = ["CUSTOMER_ADMIN"];

export const DevicesList = [
  { size: 1.54, width: 200, height: 200 },
  { size: 2.13, width: 250, height: 120 },
  { size: 2.66, width: 296, height: 152 },
  { size: 2.9, width: 296, height: 128 },
  { size: 4.2, width: 400, height: 300 },
  { size: 5.4, width: 648, height: 480 },
  { size: 7.5, width: 800, height: 480 },
  { size: 10, width: 960, height: 640 },
];
