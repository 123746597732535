import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Input from '../../components/form/input'
import Select from '../../components/form/select';
import { handleValidation } from '../../components/utilities/validations';
import Textarea from '../../components/form/textarea';
import { getUnAllocatedCPE, StoreAdd } from '../../store/store/storeService';
import { GetAllCpeList } from '../../store/devices/deviceService';
import { Loader } from '../../components/utilities/loader';

export default function AddStore() {

    const [state, setState] = useState({ storeId: null, storeName: '', address: '', cpeDevice: '' });
    const [value, setValue] = useState({ viewPassword: false, isUpdate: false, cpeOptions: [] });
    const [error, setError] = useState({});
    const { addLoad, storeData, unAllocatedCPE } = useSelector(state => state.stores);
    const { loading, cpeData } = useSelector(state => state.cpeList);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        dispatch(GetAllCpeList());
        dispatch(getUnAllocatedCPE());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (id) {
            let store = storeData.find(o => o.storeId === id);
            setValue(value => ({ ...value, isUpdate: true, cpeOptions: [] }));
            const selected = cpeData?.find((data) => data.id === store?.deviceId)
            const cpeOptions = unAllocatedCPE?.map((v) => {
                return {
                    key: v.id,
                    name: v.name + ' - ' + v.serialNum
                }
            });
            console.log('ttt', selected)
            if (selected) {
                cpeOptions.push({ key: selected?.id, name: selected?.name + ' - ' + selected?.serialNum });
            }
            setValue(value => ({ ...value, isUpdate: true, cpeOptions: cpeOptions }));
            setState(state => ({ ...state, storeName: store?.storeName, address: store?.storeAddress, cpeDevice: store?.deviceId }))
        } else {
            if (unAllocatedCPE && typeof (unAllocatedCPE) === 'object' && unAllocatedCPE.length > 0) {
                const cpeOptions = unAllocatedCPE?.map((v, i) => {
                    return {
                        key: v.id,
                        name: v.name + ' - ' + v.serialNum
                    }
                });
                setValue(value => ({ ...value, isUpdate: false, cpeOptions: cpeOptions }));
            } else {
                setValue(value => ({ ...value, isUpdate: false, cpeOptions: [] }));
            }
            setState(state => ({ ...state, storeName: '', address: '', cpeDevice: '' }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, unAllocatedCPE, storeData, cpeData]);

    const handleChange = (e) => {
        e.persist();
        setState(state => ({ ...state, [e.target.name]: e.target.value }));
    }
    const handleSubmit = (e) => {

        e?.preventDefault();
        let conditions = [
            { condition: 'required', name: 'storeName', value: state.storeName, text: 'Site Name' },
            { condition: 'required', name: 'address', value: state.address, text: 'Address' },
            // { condition: 'required', name: 'cpeDevice', value: state.cpeDevice, text: 'CPE Device' }
        ]
        let errors = handleValidation(conditions);

        if (!errors.isError) {
            setError({});
            let data = {
                storeName: state.storeName,
                storeAddress: state.address,
                deviceId: state.cpeDevice,
                storeId: id ?? 0,
                // customerId: 1
            }
            dispatch(StoreAdd(data)).then(resp => {
                if (resp.status === 200) navigate('/store-list');
            });

        } else {
            setError(errors.errors)
        }
    }

    return (
        <>
            <div className="row d-flex align-items-center mb-3 mb-md-5">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">{`${value.isUpdate ? 'Edit' : 'Add'} Site`}</p>
                </div>
                <div className="col-md-6 text-start text-md-end">
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-10">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <Input type='text' value={state.storeName} name='storeName' placeholder='Enter Site Name' onchange={handleChange} label={'Site Name'} autoFocus={true} error={error?.storeName} required />
                        </div>
                        <div className="col-12 col-md-12">
                            <Textarea value={state.address} name='address' placeholder='Enter Address' onchange={handleChange} label={'Address'} error={error?.address} required maxlength={254} />
                        </div>
                        <div className="col-12 col-md-12">
                            <Select name='cpeDevice' value={state.cpeDevice} label={'Base Station'} onchange={handleChange} options={[...value.cpeOptions]} error={error?.cpeDevice} loading={loading} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center mt-4 mb-5">
                            <button className={`btn ${value.isUpdate ? 'btn-warning' : 'btn-theme'}`} onClick={(e) => addLoad ? null : handleSubmit(e)}>
                                {addLoad ? <Loader /> : <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg>} {addLoad ? "Loading" : value.isUpdate ? "Update Site" : "Add Site"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
