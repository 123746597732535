import { Types } from "../types";

const initialState = {
    loading: false,
    addLoad: false,
    storeData: [],
    unAllocatedCPE: [],
}

export const storeReducer = (state = initialState, action) => {
    switch (action.type) {

        case Types.STORE_REQUEST:
            return {
                ...state,
                loading: true,
                storeData: [],
            }
        case Types.STORE_SUCCESS:
            return {
                ...state,
                loading: false,
                storeData: action.payload ?? [],
            }
        case Types.STORE_ERROR:
            return {
                ...state,
                loading: false,
            }

        case Types.STORE_ADD_REQUEST:
            return {
                ...state,
                addLoad: true,
            }
        case Types.STORE_ADD_SUCCESS:
            return {
                ...state,
                addLoad: false,
            }
        case Types.STORE_ADD_ERROR:
            return {
                ...state,
                addLoad: false,
            }
        case Types.UN_ALLOCATED_CPE_REQUEST:
            return {
                ...state,
                loading: true,
                unAllocatedCPE: [],
            }
        case Types.UN_ALLOCATED_CPE_SUCCESS:
            return {
                ...state,
                loading: false,
                unAllocatedCPE: action.payload ?? [],
            }
        case Types.UN_ALLOCATED_CPE_ERROR:
            return {
                ...state,
                loading: false,
            }

        default:
            return state;
    }
}