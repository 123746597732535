import axios from 'axios';

// export const URL = 'http://localhost:8091'
// export const URL = 'http://localhost:9090/http://192.168.1.33:8091'
export const URL = 'http://localhost:4000/'


export default function api() {
  let config = {
    baseURL: URL,
    timeout: 5000,
    headers: { "Content-Type": "application/json", }
  }

  let api = axios.create(config);
  return api;
}