import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../components/form/select';
import { Link } from 'react-router-dom';
import { CustomModal, ModalList } from '../../components/form/modal';
import { Loader } from '../../components/utilities/loader';
import DataTable from '../../components/utilities/table'
import { addFavorite, getTemplateList } from '../../store/template/templateService';

export default function ListTemplate() {

    const dispatch = useDispatch();

    const [state, setState] = useState({ data: [], data2: [], dropItem: 0, filterOptions: [], filterValue: '' });
    const [modal, setModal] = useState({ deleteModal: false, deleteData: {}, viewData: {}, viewModal: false });
    const [fav, setFav] = useState([])

    const { loading, templateData } = useSelector(state => state.templates);

    useEffect(() => {
        dispatch(getTemplateList());
        // dispatch(getTemplateFiles());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const sizes = []
        const data = templateData?.map((val, i) => {
            const tempImage = val.templateInfo?.replace('inch', '')?.trim() + ' inch';
            const find = sizes.find(o => o.name === tempImage);
            if (!find) sizes.push({ key: tempImage, name: tempImage, size: tempImage.replace(" inch", "") })
            return {
                no: i + 1,
                template: val.templateImageUrl,
                templateBase: `data:image/png;base64,${val.templateImage}`,
                name: val.templateInfo,
                size: tempImage,
                id: val.templateId,
                templateNo: Number(val.fileName),
                editable: val.editable,
                favorite: val.favorite
            }
        });
        sizes.sort((a, b) => a.size - b.size);
        setState(state => ({ ...state, data: data, data2: data, filterOptions: sizes }));
    }, [templateData]);

    const header = [
        { headerName: '#', field: 'no', editable: false, resizable: true, maxWidth: 70 },
        {
            headerName: 'Template', editable: false, minWidth: 500, cellRendererFramework: (param) => {
                return (
                    <React.Fragment>
                        <img src={param.data.templateBase} alt={'template'} style={{ padding: '10px' }} width='50%' />
                    </React.Fragment>
                )
            }
        },
        { headerName: 'Name', field: 'name', editable: false, resizable: true, },
        {
            headerName: 'Favorite', field: 'favorite', maxWidth: 150, editable: false, cellRendererFramework: (param) => {
                return (
                    <React.Fragment>
                        <img className='pointer icon-padding' src={fav[param.data.id] ?? param.data.favorite ? '/img/fillheart.png' : '/img/heart.png'} alt={'fav'} onClick={() => handleAddFav(param.data)} width={22} />
                    </React.Fragment>
                )
            }
        },
        {
            headerName: 'Action', editable: false, minWidth: 200, cellRendererFramework: (param) => {
                return (
                    <React.Fragment>
                        {/* <i className='fa fa-info-circle text-primary icon-padding icon16' onClick={() => viewHandle(param.data)} /> */}
                        <img className='pointer icon-padding' src={'/img/info.png'} alt={'info'} onClick={() => viewHandle(param.data)} width={22} />
                        {/* { */}
                        {param.data.editable && param.data.templateNo === 2 ?
                            <Link to={'/edit-template/' + param.data.id} >
                                <img className='pointer icon-padding' src={'/img/edit.png'} alt={'info'} width={22} />
                            </Link>
                            : null
                        }
                        {/* <i className='fa fa-trash text-danger icon-padding' onClick={() => modalHandle(param.data)} /> */}
                    </React.Fragment>
                )
            }
        },

    ];

    const viewHandle = (data) => {
        setModal(modal => ({ ...modal, viewData: data, viewModal: true }));
    }

    // const modalHandle = (data) => {
    //     setModal(modal => ({ ...modal, deleteData: data, deleteModal: true }));
    // }

    const closeHandle = (data) => {
        setModal(modal => ({ ...modal, deleteData: {}, deleteModal: false, viewData: {}, viewModal: false }));

    }

    const filterHandle = (text) => {
        const filters = state.data2.filter(o => o.name === text);
        setState(state => ({ ...state, data: text ? filters : state.data2, filterValue: text }))
    }

    const handleAddFav = (temp) => {
        const isSelect = fav[temp.id] === undefined ? !temp.favorite : !fav[temp.id]
        dispatch(addFavorite(temp.id, isSelect))
        setFav({ ...fav, [temp.id]: isSelect })
        const afData = []
        state?.data.map((v, index) => {
            if (v.id === temp.id) {
                v.favorite = isSelect
            }
            afData.push(v)
        })
        setState(state => ({ ...state, data: afData }))
    }

    return (
        <>
            <div className="row d-flex align-items-center mb-5 mb-md-2">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">Templates Library {loading ? <Loader /> : null}</p>
                </div>
            </div>
            <div className="row d-flex justify-content-end">
                <DataTable key={state.data} headers={header} data={state.data} pagination={true} globalSearch={false} >
                    <div className='justify-content-between' style={{ marginTop: '4rem' }}>
                        <div className="col-12 col-md-6 col-lg-6 mb-3">
                            <div className='text-nowrap position-absolute ' style={{ marginTop: '-6rem', width: '20%' }}>
                                <Select options={state.filterOptions} customPlaceholder={'Filter by Inch'} onchange={(e) => filterHandle(e.target.value)} value={state.filterValue} />
                            </div>
                        </div>
                    </div>
                </DataTable>
            </div>

            {modal.viewModal ?
                <CustomModal onclose={closeHandle}>
                    <div className="row d-flex align-items-center mb-3 mb-md-5">
                        <div className="col-md-12">
                            <p className="medium ft-23 mb-2 mb-md-0" style={{ marginLeft: '2rem' }}>Template Detail</p>
                        </div>
                        <div className="col-md-6 text-start text-md-end">
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row">
                                <ModalList title='Template Info' value={modal.viewData?.name} size={12} />
                                <ModalList title='Template ID' value={modal.viewData?.id} size={12} />
                                <div className="d-flex bold">
                                    Template:
                                    <img src={modal?.viewData?.templateBase} alt={'template'} style={{ padding: '10px' }} width={400} />
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal> : null}

            {/* {modal.deleteModal ?
                <CustomModal onclose={closeHandle}>
                    <p className="medium ft-23 mb-1">{`Are you Sure to Delete ${modal.deleteData?.size}?`}</p>
                    <div className="text-center mb-5 mt-4">
                        <span className="btn btn-secondary" onClick={closeHandle}>Close</span>
                        <span className={`btn btn-danger`} onClick={deleteHandle}>{templateDeleteLoading ? <><Loader /> Loading</> : 'Delete'}</span>
                    </div>
                </CustomModal> : null} */}
        </>
    )
}
