export const Types = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  USER_LOGOUT: "USER_LOGOUT",

  FORGOT_REQUEST: "FORGOT_REQUEST",
  FORGOT_SUCCESS: "FORGOT_SUCCESS",
  FORGOT_ERROR: "FORGOT_ERROR",

  RESET_REQUEST: "RESET_REQUEST",
  RESET_SUCCESS: "RESET_SUCCESS",
  RESET_ERROR: "RESET_ERROR",

  ALERT_ERROR: "ALERT_ERROR",
  ALERT_SUCCESS: "ALERT_SUCCESS",
  ALERT_WARNING: "ALERT_WARNING",
  ALERT_INFO: "ALERT_INFO",
  ALERT_DEFAULT: "ALERT_DEFAULT",
  ALERT_CLEAR: "ALERT_CLEAR",

  NAVIGATION_LABEL: "NAVIGATION_LABEL",

  DASHBOARD_REQUEST: "DASHBOARD_REQUEST",
  DASHBOARD_REQUEST_SUCCESS: "DASHBOARD_REQUEST_SUCCESS",
  DASHBOARD_REQUEST_ERROR: "DASHBOARD_REQUEST_ERROR",

  USERS_REQUEST: "USERS_REQUEST",
  USERS_SUCCESS: "USERS_SUCCESS",
  USERS_ERROR: "USERS_ERROR",

  USER_ADD_REQUEST: "USER_ADD_REQUEST",
  USER_ADD_SUCCESS: "USER_ADD_SUCCESS",
  USER_ADD_ERROR: "USER_ADD_ERROR",

  ESL_REQUEST: "ESL_REQUEST",
  ESL_SUCCESS: "ESL_SUCCESS",
  ESL_ERROR: "ESL_ERROR",

  ESL_SIZE_REQUEST: "ESL_SIZE_REQUEST",
  ESL_SIZE_SUCCESS: "ESL_SIZE_SUCCESS",
  ESL_SIZE_ERROR: "ESL_SIZE_ERROR",

  UN_ALLOCATED_CPE_SUCCESS: "UN_ALLOCATED_CPE_SUCCESS",
  UN_ALLOCATED_CPE_REQUEST: "UN_ALLOCATED_CPE_REQUEST",
  UN_ALLOCATED_CPE_ERROR: "UN_ALLOCATED_CPE_ERROR",

  ESL_ADD_REQUEST: "ESL_ADD_REQUEST",
  ESL_ADD_SUCCESS: "ESL_ADD_SUCCESS",
  ESL_ADD_ERROR: "ESL_ADD_ERROR",

  ESL_DELETE_REQUEST: "ESL_DELETE_REQUEST",
  ESL_DELETE_SUCCESS: "ESL_DELETE_SUCCESS",
  ESL_DELETE_ERROR: "ESL_DELETE_ERROR",

  ESL_SCAN_REQUEST: "ESL_SCAN_REQUEST",
  ESL_SCAN_SUCCESS: "ESL_SCAN_SUCCESS",
  ESL_SCAN_ERROR: "ESL_SCAN_ERROR",

  STORE_REQUEST: "STORE_REQUEST",
  STORE_SUCCESS: "STORE_SUCCESS",
  STORE_ERROR: "STORE_ERROR",

  STORE_ADD_REQUEST: "STORE_ADD_REQUEST",
  STORE_ADD_SUCCESS: "STORE_ADD_SUCCESS",
  STORE_ADD_ERROR: "STORE_ADD_ERROR",

  CPE_REQUEST: "CPE_REQUEST",
  CPE_SUCCESS: "CPE_SUCCESS",
  CPE_ERROR: "CPE_ERROR",

  PRODUCT_REQUEST: "PRODUCT_REQUEST",
  PRODUCT_SUCCESS: "PRODUCT_SUCCESS",
  PRODUCT_ERROR: "PRODUCT_ERROR",

  PRODUCT_ADD_REQUEST: "PRODUCT_ADD_REQUEST",
  PRODUCT_ADD_SUCCESS: "PRODUCT_ADD_SUCCESS",
  PRODUCT_ADD_ERROR: "PRODUCT_ADD_ERROR",

  PRODUCT_DELETE_REQUEST: "PRODUCT_DELETE_REQUEST",
  PRODUCT_DELETE_SUCCESS: "PRODUCT_DELETE_SUCCESS",
  PRODUCT_DELETE_ERROR: "PRODUCT_DELETE_ERROR",

  PRODUCT_BIND_REQUEST: "PRODUCT_BIND_REQUEST",
  PRODUCT_BIND_SUCCESS: "PRODUCT_BIND_SUCCESS",
  PRODUCT_BIND_ERROR: "PRODUCT_BIND_ERROR",

  PRODUCT_BIND_DELETE_REQUEST: "PRODUCT_BIND_DELETE_REQUEST",
  PRODUCT_BIND_DELETE_SUCCESS: "PRODUCT_BIND_DELETE_SUCCESS",
  PRODUCT_BIND_DELETE_ERROR: "PRODUCT_BIND_DELETE_ERROR",

  TEMPLATE_ADD_REQUEST: "TEMPLATE_ADD_REQUEST",
  TEMPLATE_ADD_SUCCESS: "TEMPLATE_ADD_SUCCESS",
  TEMPLATE_ADD_ERROR: "TEMPLATE_ADD_ERROR",

  TEMPLATE_DELETE_REQUEST: "TEMPLATE_DELETE_REQUEST",
  TEMPLATE_DELETE_SUCCESS: "TEMPLATE_DELETE_SUCCESS",
  TEMPLATE_DELETE_ERROR: "TEMPLATE_DELETE_ERROR",

  TEMPLATE_LIST_REQUEST: "TEMPLATE_LIST_REQUEST",
  TEMPLATE_LIST_SUCCESS: "TEMPLATE_LIST_SUCCESS",
  TEMPLATE_LIST_ERROR: "TEMPLATE_LIST_ERROR",

  TEMPLATE_FILE_REQUEST: "TEMPLATE_FILE_REQUEST",
  TEMPLATE_FILE_SUCCESS: "TEMPLATE_FILE_SUCCESS",
  TEMPLATE_FILE_ERROR: "TEMPLATE_FILE_ERROR",

  TEMPLATE_EDIT_REQUEST: "TEMPLATE_EDIT_REQUEST",
  TEMPLATE_EDIT_SUCCESS: "TEMPLATE_EDIT_SUCCESS",
  TEMPLATE_EDIT_ERROR: "TEMPLATE_EDIT_ERROR",

  IMAGE_ARRAY_SEND_REQUEST: "IMAGE_ARRAY_SEND_REQUEST",
  IMAGE_ARRAY_SEND_SUCCESS: "IMAGE_ARRAY_SEND_SUCCESS",
  IMAGE_ARRAY_SEND_ERROR: "IMAGE_ARRAY_SEND_ERROR",

  PRODUCT_BIND_LIST_REQUEST: "PRODUCT_BIND_LIST_REQUEST",
  PRODUCT_BIND_LIST_SUCCESS: "PRODUCT_BIND_LIST_SUCCESS",
  PRODUCT_BIND_LIST_ERROR: "PRODUCT_BIND_LIST_ERROR",

  PRODUCT_EXPORT_REQUEST: "PRODUCT_EXPORT_REQUEST",
  PRODUCT_EXPORT_SUCCESS: "PRODUCT_EXPORT_SUCCESS",
  PRODUCT_EXPORT_ERROR: "PRODUCT_EXPORT_ERROR",

  ESLTAG_EXPORT_REQUEST: "ESLTAG_EXPORT_REQUEST",
  ESLTAG_EXPORT_SUCCESS: "ESLTAG_EXPORT_SUCCESS",
  ESLTAG_EXPORT_ERROR: "ESLTAG_EXPORT_ERROR",

  PUSH_IMAGE_REQUEST: "PUSH_IMAGE_REQUEST",
  PUSH_IMAGE_SUCCESS: "PUSH_IMAGE_SUCCESS",
  PUSH_IMAGE_ERROR: "PUSH_IMAGE_ERROR",

  PRODUCT_EDIT_GROUPED: "PRODUCT_EDIT_GROUPED",

  TEMPLATE_PREVIEW_REQUEST: "TEMPLATE_PREVIEW_REQUEST",
  TEMPLATE_PREVIEW_SUCCESS: "TEMPLATE_PREVIEW_SUCCESS",
  TEMPLATE_PREVIEW_ERROR: "TEMPLATE_PREVIEW_ERROR",
};
