import axios from 'axios';
import { baseUrl } from '../API';
import { Types } from "../types";


export const GetAllCpeList = () => {
    return (dispatch) => {
        dispatch({ type: Types.CPE_REQUEST });
        return axios.get(baseUrl + '/cpe/getAllCPEsByCustomer').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.CPE_SUCCESS, payload: resp.data?.body });
            } else {
                dispatch({ type: Types.CPE_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.CPE_ERROR });
            return err;
        });
    }
};
