import { Types } from "../types";

const initialState = {
    loading: false,
    addLoad: false,
    bindinglistLoading: false,
    bindingdeleteLoading: false,
    bindAddLoad: false,
    productBindLoading: false,
    exportLoading: false,
    pushImageLoading: false,
    productData: [],
    bindingListData: [],
    productBindList: [],
    editGrouped: [],
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {

        case Types.PRODUCT_REQUEST:
            return {
                ...state,
                loading: true,
                productData: [],
            }
        case Types.PRODUCT_SUCCESS:
            return {
                ...state,
                loading: false,
                productData: action.payload ?? [],
            }
        case Types.PRODUCT_ERROR:
            return {
                ...state,
                loading: false,
            }

        case Types.PRODUCT_BIND_REQUEST:
            return {
                ...state,
                bindAddLoad: true,
            }
        case Types.PRODUCT_BIND_SUCCESS:
        case Types.PRODUCT_BIND_ERROR:
            return {
                ...state,
                bindAddLoad: false,
            }

        case Types.PRODUCT_ADD_REQUEST:
            return {
                ...state,
                addLoad: true,
            }
        case Types.PRODUCT_ADD_SUCCESS:
            return {
                ...state,
                addLoad: false,
            }
        case Types.PRODUCT_ADD_ERROR:
            return {
                ...state,
                addLoad: false,
            }

        case Types.PRODUCT_DELETE_REQUEST:
            return {
                ...state,
                deleteLoad: true,
            }
        case Types.PRODUCT_DELETE_SUCCESS:
        case Types.PRODUCT_DELETE_ERROR:
            return {
                ...state,
                deleteLoad: false,
            }
        case Types.TEMPLATE_LIST_REQUEST:
            return {
                ...state,
                bindinglistLoading: true,
                bindingListData: [],
            }
        case Types.TEMPLATE_LIST_SUCCESS:
            return {
                ...state,
                bindinglistLoading: false,
                bindinglistData: action.payload ?? [],
            }
        case Types.TEMPLATE_LIST_ERROR:
            return {
                ...state,
                bindinglistLoading: false,
            }

        case Types.PRODUCT_BIND_LIST_REQUEST:
            return {
                ...state,
                productBindLoading: true,
                productBindList: [],
            }
        case Types.PRODUCT_BIND_LIST_SUCCESS:
            return {
                ...state,
                productBindLoading: false,
                productBindList: action.payload ?? [],
            }
        case Types.PRODUCT_BIND_LIST_ERROR:
            return {
                ...state,
                productBindLoading: false,
            }

        case Types.PRODUCT_EDIT_GROUPED:
            return {
                ...state,
                editGrouped: action.payload ?? [],
            }

        case Types.PRODUCT_EXPORT_REQUEST:
            return {
                ...state,
                exportLoading: true,
            }
        case Types.PRODUCT_EXPORT_SUCCESS:
        case Types.PRODUCT_EXPORT_ERROR:
            return {
                ...state,
                exportLoading: false,
            }

        case Types.PRODUCT_BIND_DELETE_REQUEST:
            return {
                ...state,
                bindingdeleteLoading: true,
            }
        case Types.PRODUCT_BIND_DELETE_SUCCESS:
        case Types.PRODUCT_BIND_DELETE_ERROR:
            return {
                ...state,
                bindingdeleteLoading: false,
            }

        case Types.PUSH_IMAGE_REQUEST:
            return {
                ...state,
                pushImageLoading: true,
            }
        case Types.PUSH_IMAGE_SUCCESS:
        case Types.PUSH_IMAGE_ERROR:
            return {
                ...state,
                pushImageLoading: false,
            }


        default:
            return state;
    }
}