import { combineReducers } from "redux";
import { alertReducer, dashboardReducer, navigationReducer } from "./common/commonReducer";
import { cpeListReducer } from "./devices/deviceReducer";
import { eslReducer } from "./esltags/eslReducer";
import { loginReducer } from "./login/loginReducer";
import { productReducer } from "./product/productReducer";
import { storeReducer } from "./store/storeReducer";
import { templateReducer } from "./template/templateReducer";
import { Types } from "./types";
import { userReducer } from "./users/usersReducer";

const combineReducer = combineReducers({
    alert: alertReducer,
    login: loginReducer,
    navigation: navigationReducer,
    dashboard: dashboardReducer,
    usersList: userReducer,
    eslList: eslReducer,
    stores: storeReducer,
    cpeList: cpeListReducer,
    products: productReducer,
    templates: templateReducer,
});

const rootReducer = (state, action) => {
    //action to clear application redux
    if (action.type === Types.USER_LOGOUT) {
        state = {};
    }
    return combineReducer(state, action);
}

// export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
