import axios from 'axios';
import { baseUrl } from '../API';
import { Types } from "../types";

export const getUsersList = () => {

    return (dispatch) => {
        dispatch({ type: Types.USERS_REQUEST });
        axios.get(baseUrl + '/cpe/usersByCustomer').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.USERS_SUCCESS, payload: resp.data });
            }
        }).catch(err => {
            dispatch({ type: Types.USERS_ERROR });

        });
    }
};

export const UserAdd = (data) => {

    return (dispatch) => {
        dispatch({ type: Types.USER_ADD_REQUEST });
        return axios.post(baseUrl + '/cpe/add', data).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.firstName} ${data.pkId ? 'Updated' : 'Added'}` } });
                dispatch({ type: Types.USER_ADD_SUCCESS });
            } else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.USER_ADD_ERROR });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'User Add Failed' } });
                dispatch({ type: Types.USER_ADD_ERROR });
            }
            return resp;
        }).catch(err => {
            if (err?.response?.data?.error) {
                dispatch({ type: Types.USER_ADD_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response.data.error } });
            }
            else if (err?.response?.data?.message) {
                dispatch({ type: Types.USER_ADD_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response.data.message } });
            } else {
                dispatch({ type: Types.USER_ADD_ERROR });
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'User Add Failed' } });
            }
            return err;
        });
    }
};

export const ChangeStatus = (data) => {

    return (dispatch) => {
        dispatch({ type: Types.USER_ADD_REQUEST });
        return axios.post(baseUrl + '/cpe/add', data).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.firstName} ${data.isUserActive ? 'Enabled' : 'Disbaled'}` } });
                dispatch({ type: Types.USER_ADD_SUCCESS });
                dispatch(getUsersList());
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: `${data.firstName} Status Change Failed` } });
                dispatch({ type: Types.USER_ADD_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.USER_ADD_ERROR });
            dispatch({ type: Types.ALERT_ERROR, payload: { message: `${data.firstName} Status Change Failed` } });
        });
    }
};

export const UserLogout = () => {

    return (dispatch) => {
        dispatch({ type: Types.USER_LOGOUT, payload: {} });
        // dispatch({ type: Types.ALERT_ERROR, payload: { message: 'User Logged Out', duration: 2000 } });
    }
};

// export const userLogout = () => {
//   return async (dispatch: Dispatch<UserLoginActions | AlertAction>) => {
//     try {
//       const response: IResponseData = await client.post(`${authUrl}logout`);
//       if (response.status === 200) {
//         clearSession();
//         dispatch({ type: UserLoginType.USER_LOGOUT });
//       }
//       return response;
//     } catch (error: any) {
//       const errorPayload: IAlertPayload = {
//         message: error.message,
//         delay: 5000
//       };
//       dispatch({
//         type: AlertType.ALERT_ERROR, payload: errorPayload
//       });
//       return error;
//     }
//   };
// };
