import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

export default function DataTable({ data, pagination, headers, globalSearch, searchClear, children }) {

    const [rows] = useState(50);
    const gridRef = useRef();
    const [feedData, setData] = useState(data);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setData(data);
    }, [data]);

    useEffect(() => {
        if (searchClear) {
            handleSearch('');
        }
    }, [searchClear]);

    const defaultColDef = {
        editable: true,
        sortable: true,
        resizable: true,
        cellClass: 'cell-wrap-text',
        autoHeight: true,
        filter: true,
        flex: 1,
        minWidth: 50,
    };

    const onGridReady = params => {
        // this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;
        // gridRef.current.api.gridOptionsWrapper.gridOptions.getRowHeight(150);
    };

    const handleSearch = (value) => {
        let search = value;
        let users = data;
        const filteredData = users.filter(item => {
            return Object.keys(item).some(key => {
                const regex = new RegExp(`${search.trim()}`, 'i');
                return regex.test(item[key]?.toString().toLowerCase());
            }
            );
        });
        setSearch(search);
        setData(filteredData);
    }


    return (
        <span style={{ height: '100%' }}>
            {globalSearch ?
                <>
                    <div className="col-12 col-md-5 col-lg-3 mb-3" style={{ float: 'right' }}>
                        <input type="text" placeholder="Search" value={search} className="form-control" onChange={(e) => handleSearch(e.target.value)} />
                    </div><br /><br /><br />
                </>
                : null}
            <span>{children}</span>
            {/* {...children} */}
            <div className="test-container">
                {/* <div className='row'>
                    <div className='col-6'>
                        Show &nbsp;
                        <select className='btn btn-sm' value={rows} onChange={(e) => setRows(Number(e.target.value))}>
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={6}>6</option>
                        </select>
                        &nbsp;Rows
                    </div>
                </div> */}

                <div id="myGrid" style={{ height: '70vh', overflowX: 'scroll', }} className="myGrid ag-theme-alpine">
                    <AgGridReact
                        suppressBrowserResizeObserver={true}
                        id='myGrid2'
                        ref={gridRef}
                        // modules={AllModules}
                        columnDefs={headers}
                        defaultColDef={defaultColDef}
                        suppressRowClickSelection={true}
                        groupSelectsChildren={true}
                        debug={false}
                        rowSelection={'multiple'}
                        pagination={pagination ? true : false}
                        paginationPageSize={rows}
                        onGridReady={onGridReady}
                        rowData={feedData}
                        domLayout={''}
                        getRowHeight={[300]}
                        enableCellTextSelection="true"

                    />
                </div>
            </div>
        </span>
    );
}
