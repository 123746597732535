import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomModal, ModalList } from '../../components/form/modal';
import { AdminRoles } from '../../components/utilities/functions';
import { Loader } from '../../components/utilities/loader';
import DataTable from '../../components/utilities/table'
import { Navigation } from '../../store/common/commonService';
import { getStoreList } from '../../store/store/storeService';

export default function ListStore() {

    const dispatch = useDispatch();

    const [state, setState] = useState({ data: [] });
    const [modal, setModal] = useState({ enableModal: false, enableData: {}, viewData: {}, viewModal: false });

    const { loading, storeData } = useSelector(state => state.stores);
    const login = useSelector(state => state.login);
    const userType = login?.userData?.userType;


    useEffect(() => {
        dispatch(getStoreList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = storeData.map((val, i) => {
            return {
                no: i + 1,
                storeName: val.storeName,
                storeAddress: val.storeAddress,
                deviceName: val.deviceName,
                id: val.storeId
            }
        });
        setState(state => ({ ...state, data: data }))
    }, [storeData]);

    const header = [
        { headerName: '#', field: 'no', editable: false, resizable: true, maxWidth: 70 },
        // { headerName: 'Name', field: 'name', editable: false, resizable: true, },
        { headerName: 'Site Name', field: 'storeName', editable: false, resizable: true },
        { headerName: 'Address', field: 'storeAddress', maxWidth: 150, editable: false, resizable: true },
        { headerName: 'Base Station', field: 'deviceName', editable: false, resizable: true },
        {
            headerName: 'Action', editable: false, minWidth: 200, cellRendererFramework: (param) => {
                return (
                    <React.Fragment>
                        {/* <i className='fa fa-info-circle text-theme icon-padding icon16' onClick={() => viewHandle(param.data)} /> */}
                        <img className='pointer icon-padding' src={'/img/info.png'} alt={'info'} onClick={() => viewHandle(param.data)} width={22} />
                        {/* {AdminRoles.includes(userType) ? */}
                        <Link to={'/store-edit/' + param.data.id} ><img className='pointer icon-padding' src={'/img/edit.png'} alt={'info'} width={22} /></Link>

                        {/* // : null} */}
                    </React.Fragment>
                )
            }
        },
    ];

    const viewHandle = (data) => {
        setModal(modal => ({ ...modal, viewData: data, viewModal: true }));
    }

    const closeHandle = (data) => {
        setModal(modal => ({ ...modal, enableModal: false, enableData: {}, viewData: {}, viewModal: false }));
    }

    return (
        <div>
            <div className="row d-flex align-items-center mb-3 mb-md-2">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">Site List {loading ? <Loader /> : null}</p>
                </div>
                {AdminRoles.includes(userType) ?
                    <div className="col-md-6 text-start text-md-end">
                        <Link className="btn btn-theme" to="/store-add" onClick={() => dispatch(Navigation('storeAdd'))}> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg> Add Site</Link>
                    </div> : null}
            </div>
            <div className="row d-flex justify-content-end">
                <DataTable key={state.data} headers={header} data={state.data} pagination={true} globalSearch={true} />
            </div>

            {modal.viewModal ?
                <CustomModal onclose={closeHandle}>
                    <div className="row d-flex align-items-center mb-3 mb-md-5">
                        <div className="col-md-12">
                            <p className="medium ft-23 mb-2 mb-md-0" style={{ marginLeft: '2rem' }}>Site Detail</p>
                        </div>
                        <div className="col-md-6 text-start text-md-end">
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row">
                                <ModalList title='Site Name' value={modal.viewData?.storeName} size={12} />
                                <ModalList title='Address' value={modal.viewData?.storeAddress} size={12} />
                                <ModalList title='Base Station' value={modal.viewData?.deviceName} size={12} />
                            </div>
                        </div>
                    </div>
                </CustomModal> : null}

        </div>

    )
}
