import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CustomModal, ModalList } from '../../components/form/modal';
import { AdminRoles, RoleOptions } from '../../components/utilities/functions';
import { Loader } from '../../components/utilities/loader';
import DataTable from '../../components/utilities/table'
import { Navigation } from '../../store/common/commonService';
import { ChangeStatus, getUsersList } from '../../store/users/usersService';

export default function ListUsers() {

    const dispatch = useDispatch();

    const [state, setState] = useState({ data: [] });
    const [modal, setModal] = useState({ enableModal: false, enableData: {}, viewData: {}, viewModal: false });

    const { loading, addLoad, userData } = useSelector(state => state.usersList);
    const login = useSelector(state => state.login);
    const userType = login?.userData?.userType;


    useEffect(() => {
        dispatch(getUsersList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const data = userData.map((val, i) => {
            const role = RoleOptions.find(o => o.key === val.userType);
            return {
                no: i + 1,
                email: val.userEmail,
                role: role?.name,
                store: val.storeName,
                status: val.isUserActive,
                password: val.userPassword,
                type: val.userType,
                firstName: val.firstName,
                lastName: val.lastName,
                id: val.userId,
                phoneNumber: val.phoneNumber,
                created: val.passwordCreatedDate ? moment(val.passwordCreatedDate).format('MM/DD/YYYY') : '',
            }
        });
        setState(state => ({ ...state, data: data }))
    }, [userData]);

    const header = [
        { headerName: '#', field: 'no', editable: false, resizable: true, maxWidth: 70 },
        // { headerName: 'Name', field: 'name', editable: false, resizable: true, },
        { headerName: 'Email', field: 'email', editable: false, resizable: true },
        { headerName: 'Role', field: 'role', maxWidth: 150, editable: false, resizable: true },
        { headerName: 'Site', field: 'store', editable: false, resizable: true },
        {
            headerName: 'Active', field: 'status', editable: false, maxWidth: 120, cellRendererFramework: (param) => {
                if (param.data?.status) {
                    return <i className='fa fa-check text-success fz-20' />;
                }
                return <i className='fa fa-times text-danger fz-20' />;
            }
        },
        {
            headerName: 'Action', field: 'status', editable: false, minWidth: 200, cellRendererFramework: (param) => {
                return (
                    <React.Fragment>
                        {/* <i className='fa fa-info-circle text-primary icon-padding icon16' onClick={() => viewHandle(param.data)} /> */}
                        <img className='pointer icon-padding' src={'/img/info.png'} alt={'info'} onClick={() => viewHandle(param.data)} width={22} />
                        {AdminRoles.includes(userType) ?
                            <Fragment>
                                <Link to={'/user-edit/' + param.data.id} ><img className='pointer icon-padding' src={'/img/edit.png'} alt={'info'} width={22} /></Link>
                                {/* <Link to={'/user-edit/' + param.data.id} ><i className='fa fa-edit text-primary icon-padding' /></Link> */}

                                <button className={`btn btn-sm ${param.data.status ? 'btn-danger' : 'btn-theme'} ml-10`} onClick={() => modalHandle(param.data)}>{param.data.status ? <> <i className='fa fa-times' /> Disable </> : <> <i className='fa fa-check' /> Enable</>}</button>
                            </Fragment>
                            : null}
                        {/* <i className='fa fa-trash text-danger icon-padding' /> */}
                    </React.Fragment>
                )
            }
        },
    ];

    const viewHandle = (data) => {
        setModal(modal => ({ ...modal, viewData: data, viewModal: true }));
    }

    const modalHandle = (data) => {
        setModal(modal => ({ ...modal, enableData: data, enableModal: true }));
    }

    const closeHandle = (data) => {
        setModal(modal => ({ ...modal, enableModal: false, enableData: {}, viewData: {}, viewModal: false }));
    }

    const statusHandle = () => {
        let mdata = modal.enableData
        let data = {
            email: mdata.email,
            password: mdata.password,
            firstName: mdata.firstName,
            lastName: mdata.lastName,
            userType: mdata.type,
            isUserActive: !mdata.status,
            pkId: mdata.id,
        }
        dispatch(ChangeStatus(data)).then(resp => {
            if (resp.status === 200) setModal(modal => ({ ...modal, enableModal: false, enableData: {} }));
        });
    }

    return (
        <div>
            <div className="row d-flex align-items-center mb-3 mb-md-2">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">User List {loading ? <Loader /> : null}</p>
                </div>
                {AdminRoles.includes(userType) ?
                    <div className="col-md-6 text-start text-md-end">
                        <Link className="btn btn-theme" to="/user-add" onClick={() => dispatch(Navigation('userAdd'))}> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg> Add User</Link>
                    </div> : null}
            </div>
            <div className="row d-flex justify-content-end">
                <DataTable key={state.data} headers={header} data={state.data} pagination={true} globalSearch={true} />
            </div>
            {modal.enableModal ?
                <CustomModal onclose={closeHandle}>
                    <p className="medium ft-23 mb-1">{`Are you Sure to ${modal.enableData?.status ? 'Disbale' : 'Enable'} ${modal.enableData?.email}?`}</p>
                    <div className="text-center mb-5 mt-4">
                        <span className="btn btn-secondary" onClick={closeHandle}>Close</span>
                        <span className={`btn ${modal.enableData?.status ? 'btn-danger' : 'btn-theme'}`} onClick={statusHandle}>{addLoad ? <><Loader /> Loading</> : modal.enableData?.status ? 'Disable' : 'Enable'}</span>
                    </div>
                </CustomModal> : null}

            {modal.viewModal ?
                <CustomModal onclose={closeHandle}>
                    <div className="row d-flex align-items-center mb-3 mb-md-5">
                        <div className="col-md-12">
                            <p className="medium ft-23 mb-4 mb-md-0 bold" >User Detail</p>
                        </div>
                        <div className="col-md-6 text-start text-md-end">
                        </div>
                    </div>

                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10">
                            <div className="row">
                                <ModalList title='First Name' value={modal.viewData?.firstName} />
                                <ModalList title='Last Name' value={modal.viewData?.lastName} />
                                <ModalList title='Email' value={modal.viewData?.email} />
                                <ModalList title='Phone Number' value={modal.viewData?.phoneNumber} />
                                <ModalList title='Role' value={modal.viewData?.role} />
                                <ModalList title='Site' value={modal.viewData?.store} />
                                <ModalList title='Status' value={modal.viewData.status ? 'Active' : 'Disabled'} />
                                <ModalList title='Created' value={modal.viewData?.created} />
                            </div>
                        </div>
                    </div>
                </CustomModal> : null}
        </div>

    )
}
