import { Types } from "../types";

const initialState = {
    loading: false,
    addLoad: false,
    userData: []
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case Types.USERS_REQUEST:
            return {
                ...state,
                loading: true,
                userData: []
            }
        case Types.USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                userData: action.payload ?? [],
            }
        case Types.USERS_ERROR:
            return {
                ...state,
                loading: false,
            }

        case Types.USER_ADD_REQUEST:
            return {
                ...state,
                addLoad: true,
            }
        case Types.USER_ADD_SUCCESS:
            return {
                ...state,
                addLoad: false,
            }
        case Types.USER_ADD_ERROR:
            return {
                ...state,
                addLoad: false,
            }

        default:
            return state;
    }
}