import { Types } from "../types";

const initialState = {
  loading: false,
  addLoad: false,
  previewLoading: false,
  templateFileLoading: false,
  templateDeleteLoading: false,
  templateData: [],
  templateFiles: [],
  editedTemplate: [],
  previewData: [],
};

export const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.TEMPLATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        templateData: [],
      };
    case Types.TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        templateData: action.payload ?? [],
      };
    case Types.TEMPLATE_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    case Types.TEMPLATE_FILE_REQUEST:
      return {
        ...state,
        templateFileLoading: true,
        templateFiles: [],
      };
    case Types.TEMPLATE_FILE_SUCCESS:
      return {
        ...state,
        templateFileLoading: false,
        templateFiles: action.payload ?? [],
      };
    case Types.TEMPLATE_FILE_ERROR:
      return {
        ...state,
        templateFileLoading: false,
      };

    case Types.TEMPLATE_ADD_REQUEST:
      return {
        ...state,
        addLoad: true,
      };
    case Types.TEMPLATE_ADD_SUCCESS:
      return {
        ...state,
        addLoad: false,
      };
    case Types.TEMPLATE_ADD_ERROR:
      return {
        ...state,
        addLoad: false,
      };

    case Types.TEMPLATE_DELETE_REQUEST:
      return {
        ...state,
        templateDeleteLoading: true,
      };
    case Types.TEMPLATE_DELETE_SUCCESS:
    case Types.TEMPLATE_DELETE_ERROR:
      return {
        ...state,
        templateDeleteLoading: false,
      };
    // case Types.TEMPLATE_EDIT_REQUEST:
    //     break
    // case Types.TEMPLATE_EDIT_SUCCESS:

    // case Types.TEMPLATE_EDIT_ERROR:
    //     break

    case Types.TEMPLATE_PREVIEW_REQUEST:
      return {
        ...state,
        previewLoading: true,
      };
    case Types.TEMPLATE_PREVIEW_SUCCESS:
    case Types.TEMPLATE_PREVIEW_ERROR:
      return {
        ...state,
        previewLoading: false,
      };

    default:
      return state;
  }
};
