import axios from 'axios';
import { baseUrl } from '../API';
import { Types } from "../types";

export const getESLList = () => {

    return (dispatch) => {
        dispatch({ type: Types.ESL_REQUEST });
        axios.get(baseUrl + '/cpe/getAllEslTagsByCustomerId').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ESL_SUCCESS, payload: resp.data });
            }
        }).catch(err => {
            dispatch({ type: Types.ESL_ERROR });

        });
    }
};

export const eslUpload = (data) => {

    return (dispatch) => {
        dispatch({ type: Types.PRODUCT_ADD_REQUEST });
        return axios.post(baseUrl + '/cpe/addEslsWithCsv', data).then(resp => {
            if (resp.status === 200) {
                if (resp.data && resp.data.response) {
                    resp.data.response?.reverse().map((rp, i) => {
                        if (rp?.messageType) {
                            setTimeout(() => {
                                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${rp?.validationMessage ?? "Upload Success"}`, duration: 3000 } });
                            }, i * 700);
                        } else {
                            setTimeout(() => {
                                dispatch({ type: Types.ALERT_ERROR, payload: { message: `${rp?.validationMessage ?? "Upload Success"}`, duration: 3000 } });
                            }, i * 700);
                        }
                    })
                } else {
                    dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${resp.data?.message ?? "Upload Success"}`, duration: 3000 } });
                }
                dispatch({ type: Types.PRODUCT_ADD_SUCCESS });
            }
            else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.PRODUCT_ADD_ERROR });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Product Upload Failed' } });
                dispatch({ type: Types.PRODUCT_ADD_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.PRODUCT_ADD_ERROR });
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Product Upload Failed' } });
            }
            return err;
        });
    }
};

export const getESLSize = () => {

    return (dispatch) => {
        dispatch({ type: Types.ESL_SIZE_REQUEST });
        axios.get(baseUrl + '/cpe/esl/size').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ESL_SIZE_SUCCESS, payload: resp.data });
            }
        }).catch(err => {
            dispatch({ type: Types.ESL_SIZE_ERROR });

        });
    }
};

export const EslAdd = (data) => {

    return (dispatch) => {
        dispatch({ type: Types.ESL_ADD_REQUEST });
        return axios.post(baseUrl + '/cpe/esl', data).then(resp => {
            if (resp.status === 200 || resp.status === 201) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.serialNo} ${data.tagId ? 'Updated' : 'Added'}` } });
                dispatch({ type: Types.ESL_ADD_SUCCESS });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'ESL Add Failed' } });
                dispatch({ type: Types.ESL_ADD_ERROR });
            }
            return resp;
        }).catch(err => {
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'ESL Add Failed' } });
            }
            dispatch({ type: Types.ESL_ADD_ERROR });
            return err;
        });
    }
};

export const deleteESLTags = (ids) => {

    return (dispatch) => {
        dispatch({ type: Types.ESL_DELETE_REQUEST });
        return axios.delete(baseUrl + '/cpe/delete/esl/' + ids).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ESL_DELETE_SUCCESS });
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: 'Deleted' } });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.ESL_DELETE_ERROR });
            dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed' } });
            return err;
        });
    }
};

export const eslTagsExport = () => {

    return (dispatch) => {
        dispatch({ type: Types.ESLTAG_EXPORT_REQUEST });
        return axios.post(baseUrl + '/cpe/exportEslWithCsv').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Report Generated`, duration: 500 } });
                dispatch({ type: Types.ESLTAG_EXPORT_SUCCESS });
            }
            else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.ESLTAG_EXPORT_ERROR });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Product Export Failed' } });
                dispatch({ type: Types.ESLTAG_EXPORT_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.ESLTAG_EXPORT_ERROR });
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Product Export Failed' } });
            }
            return err;
        });
    }
};

export const AutoScanEsl = (data) => {

    return (dispatch) => {
        dispatch({ type: Types.ESL_SCAN_REQUEST });
        return axios.post(baseUrl + '/cpe/autoDetectEsls', data).then(resp => {
            if (resp.status === 200 || resp.status === 201) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Success` } });
                dispatch({ type: Types.ESL_SCAN_SUCCESS });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed' } });
                dispatch({ type: Types.ESL_SCAN_ERROR });
            }
            return resp;
        }).catch(err => {
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Failed' } });
            }
            dispatch({ type: Types.ESL_SCAN_ERROR });
            return err;
        });
    }
};