import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import Input from '../../components/form/input'
import { Loader } from '../../components/utilities/loader';
import { handleValidation } from '../../components/utilities/validations';
import { saveSquarePOS } from '../../store/common/commonService';

export default function Squarepos() {
    const [state, setState] = useState({ applicationId: '', applicationSecret: '' });
    const [url, setUrl] = useState();
    const [loader, setLoader] = useState(false);
    const [errors, setError] = useState({});
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setState(state => ({ ...state, [e.target.name]: e.target.value }));
    }

    const handleSubmit = (e) => {
        e?.preventDefault();
        let conditions = [
            { condition: 'required', name: 'applicationId', value: state.applicationId, text: 'Application Id' },
            { condition: 'required', name: 'applicationSecret', value: state.applicationSecret, text: 'application Secret' },]
        let errors = handleValidation(conditions);
        if (!errors.isError) {
            setLoader(true);
            setError({});
            let data = {
                "squareAppId": state.applicationId,
                "squareAppSecret": state.applicationSecret,
            }

            dispatch(saveSquarePOS(data)).then(resp => {
                setUrl(resp.data.body)
            });
        } else {
            setError(errors.errors)
        }

    }

    const handleEnter = () => {
        if (url) {
            window.open(url, '_blank', 'noreferrer');
        }
    }

    useEffect(() => {
        if (url) {
            setLoader(false);
        }
    }, [url])


    return (
        <div className="row d-flex justify-content-center">
            <div className="col-12 col-lg-10">
                <div className="row">
                    <div className="col-12 col-md-6" >
                        <Input type='text' value={state.applicationId} name='applicationId' placeholder='Application ID' onchange={(e) => handleChange(e)} label={'Application ID'} error={errors?.applicationId} required />
                    </div>
                    <div className="col-12 col-md-6" >
                        <Input type='text' value={state.applicationSecret} name='applicationSecret' placeholder='Application Secret' onchange={handleChange} label={'Application Secret'} error={errors?.applicationSecret} required />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 text-center mt-4 mb-5">
                    <button className={`btn btn-theme`} onClick={url ? handleEnter : handleSubmit}>{loader ? <Loader /> : url ? null :
                        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>} {url ? 'Squareup Login' : 'save'}</button>
                </div>
            </div>
        </div>
    )
}
