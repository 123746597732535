import { Types } from "../types";

const initialState = {
    loading: false,
    addLoad: false,
    scanLoad: false,
    eslExportLoading: false,
    sizeLoad: false,
    deleteEslLoad: false,
    eslData: [],
    sizeData: [],
}

export const eslReducer = (state = initialState, action) => {
    switch (action.type) {

        case Types.ESL_REQUEST:
            return {
                ...state,
                loading: true,
                eslData: [],
            }
        case Types.ESL_SUCCESS:
            return {
                ...state,
                loading: false,
                eslData: action.payload ?? [],
            }
        case Types.ESL_ERROR:
            return {
                ...state,
                loading: false,
            }

        case Types.ESL_ADD_REQUEST:
            return {
                ...state,
                addLoad: true,
            }
        case Types.ESL_ADD_SUCCESS:
            return {
                ...state,
                addLoad: false,
            }
        case Types.ESL_ADD_ERROR:
            return {
                ...state,
                addLoad: false,
            }

        case Types.ESL_SIZE_REQUEST:
            return {
                ...state,
                sizeLoad: true,
                sizeData: [],
            }
        case Types.ESL_SIZE_SUCCESS:
            return {
                ...state,
                sizeLoad: false,
                sizeData: action.payload ?? [],
            }
        case Types.ESL_SIZE_ERROR:
            return {
                ...state,
                sizeLoad: false,
            }

        case Types.ESL_DELETE_REQUEST:
            return {
                ...state,
                deleteEslLoad: true,
            }
        case Types.ESL_DELETE_SUCCESS:
        case Types.ESL_DELETE_ERROR:
            return {
                ...state,
                deleteEslLoad: false,
            }

        case Types.ESLTAG_EXPORT_REQUEST:
            return {
                ...state,
                eslExportLoading: true,
            }
        case Types.ESLTAG_EXPORT_SUCCESS:
        case Types.ESLTAG_EXPORT_ERROR:
            return {
                ...state,
                eslExportLoading: false,
            }

        case Types.ESL_SCAN_REQUEST:
            return {
                ...state,
                scanLoad: true,
            }
        case Types.ESL_SCAN_SUCCESS:
            return {
                ...state,
                scanLoad: false,
            }
        case Types.ESL_SCAN_ERROR:
            return {
                ...state,
                scanLoad: false,
            }


        default:
            return state;
    }
}