import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom';

export default function SquareposSuccess() {
    const url_string = window.location.search;
    // const url = new URLSearchParams(url_string);
    // const code = url.get('code');
    // const state = url.get('state');
    const [params, setparams] = useSearchParams('')
    const code = params.get('code')
    const state = params.get('state')
    console.log('121212', code, state);
    // console.log(url_string, code, state);
    return (
        <div>Square POS Success</div>
    )
}
