import axios from 'axios';
import { baseUrl } from '../API';
import { Types } from "../types";

export const getTemplateList = () => {

    return (dispatch) => {
        dispatch({ type: Types.TEMPLATE_LIST_REQUEST });
        // axios.get(baseUrl + '/cpe/getAllocatedTemplateByCustomer').then(resp => {
        return axios.get(baseUrl + '/cpe/getAllTemplate').then(resp => {
            if (resp.status === 200 && typeof (resp.data) !== 'string') {
                dispatch({ type: Types.TEMPLATE_LIST_SUCCESS, payload: resp.data });
            } else {
                dispatch({ type: Types.TEMPLATE_LIST_SUCCESS, payload: [] });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.TEMPLATE_LIST_ERROR });
            return err;
        });
    }
};

export const getTemplateFiles = () => {

    return (dispatch) => {
        dispatch({ type: Types.TEMPLATE_FILE_REQUEST });
        axios.get(baseUrl + '/cpe/getTemplateImages').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.TEMPLATE_FILE_SUCCESS, payload: resp.data.body });
            } else {
                dispatch({ type: Types.TEMPLATE_FILE_SUCCESS, payload: [] });
            }
        }).catch(err => {
            dispatch({ type: Types.TEMPLATE_FILE_ERROR });

        });
    }
};

export const TemplateAdd = (data) => {

    return (dispatch) => {
        dispatch({ type: Types.TEMPLATE_ADD_REQUEST });
        return axios.post(baseUrl + '/cpe/addUpdateTemplateWithTemplateImage', data).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Template ${data.templateId ? 'Updated' : 'Added'}` } });
                dispatch({ type: Types.TEMPLATE_ADD_SUCCESS });
            } else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.USER_ADD_ERROR });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Add Failed' } });
                dispatch({ type: Types.TEMPLATE_ADD_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.TEMPLATE_ADD_ERROR });
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Add Failed' } });
            }
            return err;
        });
    }
};
export const addFavorite = (id, fav) => {

    return (dispatch) => {
        return axios.put(baseUrl + `/cpe/update/template/${id}/${fav}`).then(resp => {
            if (resp.status === 200) {
                fav ? dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Favorite Added` } }) :
                    dispatch({ type: Types.ALERT_ERROR, payload: { message: `Favorite Removed` } });
            } else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Update Failed' } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Update Failed' } });
            }
            return resp;
        }).catch(err => {
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Update Failed' } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Update Failed' } });
            }
            return err;
        });
    }
};

export const templateDelete = (data) => {

    return (dispatch) => {
        dispatch({ type: Types.TEMPLATE_DELETE_REQUEST });
        return axios.delete(baseUrl + '/cpe/deleteTemplate', { data }).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ALERT_WARNING, payload: { message: `${data.templateInfo} Deleted`, duration: 2000 } });
                dispatch({ type: Types.TEMPLATE_DELETE_SUCCESS });
            } else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.TEMPLATE_DELETE_ERROR });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Delete Failed' } });
                dispatch({ type: Types.TEMPLATE_DELETE_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.TEMPLATE_DELETE_ERROR });
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Delete Failed' } });
            }
            return err;
        });
    }
};

export const getTemplateImageInfo = (id) => {

    return (dispatch) => {
        return axios.get(baseUrl + '/cpe/getImageInfo/' + id).then(resp => {
            return resp;
        }).catch(err => {
            return err.response;
        });
    }
};

export const getEditedTemplateCode = (data) => {
    return (dispatch) => {
        dispatch({ type: Types.TEMPLATE_EDIT_REQUEST });
        return axios.post(baseUrl + '/cpe/addUpdateTemplateWithTemplateImage', data).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Template Edited Successfully` } });
                dispatch({ type: Types.TEMPLATE_EDIT_SUCCESS });
            } else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.USER_ADD_ERROR });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template Add Failed' } });
                dispatch({ type: Types.TEMPLATE_EDIT_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.TEMPLATE_ADD_ERROR });
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Template edit Failed' } });
            }
            return err;
        });
    }

}

export const postImageArray = (data) => {
    return (dispatch) => {
        dispatch({ type: Types.IMAGE_ARRAY_SEND_REQUEST });
        return axios.post(baseUrl + '/cpe/authenticate/makeImage/multiple', data).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `Image Added Successfully` } });
                dispatch({ type: Types.IMAGE_ARRAY_SEND_SUCCESS });
            } else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.USER_ADD_ERROR });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Image Add Failed' } });
                dispatch({ type: Types.IMAGE_ARRAY_SEND_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.IMAGE_ARRAY_SEND_ERROR });
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Image Add Failed' } });
            }
            return err;
        });
    }
}
