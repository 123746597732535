import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Multiselect from "multiselect-react-dropdown";
import Input from "../../components/form/input";
import { handleValidation } from "../../components/utilities/validations";
import { getStoreList } from "../../store/store/storeService";
import { Loader } from "../../components/utilities/loader";
import {
  productAddMultiple,
  productEditMultiple,
} from "../../store/product/productService";
import { Required } from "../../components/utilities/required";
import Textarea from "../../components/form/textarea";
import { conf } from "../../conf";

export default function AddProduct() {
  const [state, setState] = useState({
    barData: {},
    barModal: false,
    productId: null,
    id: "",
    productName: "",
    category: "",
    quantity: "",
    price: 0.01,
    discount: "",
    description: "",
    footnote: "",
    qrCodeUrl: "",
    storeItems: [],
    stores: [],
  });
  const [value, setValue] = useState({
    viewPassword: false,
    isUpdate: false,
    cpeOptions: [],
    path: "",
  });
  const [error, setError] = useState({});
  const { loading, storeData } = useSelector((state) => state.stores);
  const { addLoad, editGrouped } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getStoreList());
    // dispatch({ type: Types.PRODUCT_ADD_ERROR });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue((value) => ({ ...value, path: window.location.pathname }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (value.path === "/product-edit-grouped") {
      let editValues = editGrouped;
      const storeVal = editValues.map((v) => {
        return {
          id: v.storeId,
          name: v.storeName,
        };
      });
      setState((state) => ({
        ...state,
        id: editValues[0]?.barCode,
        productName: editValues[0]?.productName,
        category: editValues[0]?.category,
        quantity: editValues[0]?.quantity,
        discount: editValues[0]?.discount,
        price: editValues[0]?.price,
        stores: storeVal,
        description: editValues[0]?.description,
        footnote: editValues[0]?.footNote,
        qrCodeUrl: editValues[0].qrCodeUrl,
      }));
      setValue((value) => ({ ...value, isUpdate: true }));
    }
    // else if (id) {
    //     let product = productData.find(o => o.productId === Number(id));
    //     let store = storeData.find(o => o.storeId === product.storeId);
    //     if (store) {
    //         let storeVal = {
    //             id: store.storeId,
    //             name: store.storeName
    //         }
    //         setState(state => ({ ...state, stores: [storeVal] }))
    //     }
    //     setState(state => ({ ...state, id: product?.barCode, productName: product?.productName, category: product?.category, quantity: product?.quantity, price: product?.price }))
    //     setValue(value => ({ ...value, isUpdate: true }));
    // }
    else {
      setValue((value) => ({ ...value, isUpdate: false }));
      setState((state) => ({
        ...state,
        storeName: "",
        address: "",
        cpeDevice: "",
        id: "",
        productName: "",
        category: "",
        quantity: 0,
        discount: "",
        price: 0.01,
        description: "",
        footnoteL: "",
        stores: [],
      }));
    }

    // setSample(filterOptions)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, value.path]);

  useEffect(() => {
    if (storeData && storeData.length > 0) {
      const data = [];
      storeData.map((resp) => {
        if (resp?.storeName) {
          return data.push({
            id: resp.storeId,
            name: resp.storeName,
          });
        }
        return data;
      });
      setState((state) => ({ ...state, storeItems: data }));
    }
  }, [storeData]);

  const handleChange = (e) => {
    e.persist();
    setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const onSelect = (selectedList, selectedItem) => {
    setState((state) => ({ ...state, stores: selectedList }));
  };

  const onRemove = (selectedList, removedItem) => {
    setState((state) => ({ ...state, stores: selectedList }));
  };

  const handleBarCode = (data) => {
    setState({ ...state, barModal: false, barData: data, id: data.text });
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    let conditions = [
      {
        condition: "required",
        name: "productName",
        value: state.productName,
        text: "Product Name",
      },
      {
        condition: "required",
        name: "stores",
        value: state.stores.length > 0 ? state.stores : "",
        text: "Store",
      },
      // { condition: 'number', name: 'quantity', value: state.quantity, text: 'Quantity' },
      // {
      //   condition: "required",
      //   name: "quantity",
      //   value: state.quantity,
      //   text: "Quantity",
      // },
      { condition: "number", name: "price", value: state.price, text: "Price" },
      {
        condition: "number",
        name: "discount",
        value: state.discount,
        text: "Discount",
      },
      {
        condition: "required",
        name: "price",
        value: state.price,
        text: "Price",
      },
      {
        condition: "required",
        name: "category",
        value: state.category,
        text: "Category",
      },
      { condition: "required", name: "id", value: state.id, text: "ID" },
    ];
    let errors = handleValidation(conditions);

    if (!errors.isError) {
      setError({});
      if (value.path === "/product-edit-grouped") {
        let editGroup = editGrouped;
        // const data = editGroup.map((v, i) => {
        //     return {
        //         productName: state.productName,
        //         category: state.category,
        //         quantity: state.quantity,
        //         price: state.price,
        //         barCode: state.id,
        //         storeId: state.stores[i].id,
        //         productId: v.productId,
        //         customerId: v.customerId
        //     }
        // });
        const data = state.stores.map((v, i) => {
          let product = editGroup.find((o) => o.storeId === v.id);
          return {
            barCode: state.id,
            category: state.category,
            customerId: product?.customerId,
            description: state.description,
            discount: state.discount,
            footNote: state.footnote,
            qrCodeUrl: state.qrCodeUrl,
            price: state.price,
            productId: product ? product.productId : 0,
            productName: state.productName,
            quantity: state.quantity,
            storeId: v.id,
            storeName: v.name,
          };
        });
        // let data = {
        //     productId: id ?? 0,
        //     productName: state.productName,
        //     category: state.category,
        //     quantity: state.quantity,
        //     price: state.price,
        //     barCode: state.id,
        //     stores: state.stores
        // }
        dispatch(productEditMultiple(data)).then((resp) => {
          if (resp.status === 200) navigate("/product-list");
        });
      } else {
        let data = {
          productId: id ?? 0,
          productName: state.productName,
          description: state.description,
          category: state.category,
          quantity: state.quantity,
          footNote: state.footnote,
          discount: state.discount,
          qrCodeUrl: state.qrCodeUrl,
          price: state.price,
          barCode: state.id,
          stores: state.stores,
        };
        dispatch(productAddMultiple(data)).then((resp) => {
          if (resp.status === 200) navigate("/product-list");
        });
        // data['storeId'] = state.stores[0]?.id;
        // dispatch(productAdd(data)).then(resp => {
        //     if (resp.status === 200) navigate('/product-list');
        // });
      }
    } else {
      setError(errors.errors);
    }
  };
  const align = (conf.env == 'mfg' ? "col-md-4" : "col-md-6");
  return (
    <>
      <div className="row d-flex align-items-center mb-3 mb-md-5">
        <div className="col-md-6">
          <p className="medium ft-23 mb-2 mb-md-0">{`${value.isUpdate ? "Edit" : "Add"
            } Product`}</p>
        </div>
        <div className="col-md-6 text-start text-md-end"></div>
      </div>
      {state.barModal ? (
        <BarcodeScannerComponent
          width={500}
          height={200}
          onUpdate={(err, result) => {
            if (result) {
              handleBarCode(result);
            }
          }}
        />
      ) : null}
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-10">
          <div className="row">
            <div className="col-6 col-md-6">
              <div
                style={{ display: "flex", width: "100%", alignItems: "center" }}
              >
                <div className="col-10 col-md-10">
                  <Input type="text" value={state.id} name="id" placeholder={`Enter ${conf.product.id}`} onchange={handleChange} label={conf.product.id} autoFocus={true} error={error?.id} required />
                </div>
                <div> <span onClick={() => state.barModal ? setState({ ...state, barModal: false }) : setState({ ...state, barModal: true })} className="input-group-text" > <i style={{ padding: "7.75px" }} className="fa fa-camera" /> </span> </div>
              </div>
            </div>
            <div className="col-6 col-md-6">
              <Input type="text" value={state.productName} name="productName" placeholder={`Enter ${conf.product.name}`} onchange={handleChange} label={conf.product.name} error={error?.productName} maxlength={35} required />
              <span className="view-limit"> ({state.productName.length}/35) </span>
            </div>

            <div className="row">
              <div className={align}>
                <label className="mb-2">
                  {`${conf.product.store}`} <Required />{" "}
                  {loading ? <Loader /> : null}{" "}
                  {error?.stores ? (<span className="text-danger floatRight"> {error?.stores} </span>) : null}
                </label>
                <Multiselect
                  options={state.storeItems} // Options to display in the dropdown
                  selectedValues={state.stores} // Preselected value to persist in dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                // disable={value.isUpdate}
                />
              </div>
              <div className={align}>
                <Input type="text" value={state.category} name="category" placeholder={`Enter Category`} onchange={handleChange} label={"Category"} error={error?.category} required />
              </div>
              {conf.env == 'mfg' ? (
                <div className="col-sm-4">
                  <Input type="text" value={state.quantity} name="quantity" placeholder='Enter Quantity' onchange={handleChange} label='Quantity' error={error?.quantity} required />
                </div>
              ) : null}
            </div>
            {conf.env == "retail" &&
              <>
                <div className="col-6 col-md-6">
                  <Input type="text" value={state.price} name="price" placeholder={`Enter Price`} onchange={handleChange} label="Price" error={error?.price} required />
                </div>

                <div className="col-6 col-md-6">
                  <Input type="text" value={state.quantity} name="quantity" placeholder={`Enter Quantity`} onchange={handleChange} label="Quantity" error={error?.quantity} required />
                </div>

                <div className="col-6 col-md-6">
                  <div class="input-group mb-3">
                    <label className="mb-2">
                      Discount
                      {error.discount ? (<span className="text-danger floatRight"> {error.discount} </span>) : null}
                    </label>
                    <input type={"text"} className="form-control" placeholder={`Enter Discount`} name={"discount"} value={state.discount} onChange={handleChange} />
                    {/* <Input type='text' value={state.discount} name='discount' placeholder='Enter Discount' onchange={handleChange} label={'Discount'} error={error?.discount} /> */}
                    <span className="input-group-text" style={{ fontSize: "1.2rem" }} > % </span>
                  </div>
                </div>

                <div className="col-6 col-md-6">
                  <Input type="text" value={state.qrCodeUrl} name="qrCodeUrl" placeholder={`Enter QR Code URL`} onchange={handleChange} label="QR Code URL" error={error?.qrCodeUrl} />
                </div>
              </>
            }
            <div className="col-6 col-md-6">
              <Textarea value={state.description} name="description" placeholder="Enter Description" onchange={handleChange} label={"Description"} error={error?.description} maxlength={512} />
              <span className="view-limit"> ({state.description.length}/512) </span>
            </div>
            <div className="col-6 col-md-6">
              <Textarea value={state.footnote} name="footnote" placeholder={`Enter ${conf.product.footNote}`} onchange={handleChange} label={conf.product.footNote} error={error?.footnote} maxlength={512} />
              <span className="view-limit">({state.footnote.length}/512)</span>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center mt-4 mb-5">
              <button className={`btn ${value.isUpdate ? "btn-warning" : "btn-theme"}`} onClick={(e) => (addLoad ? null : handleSubmit(e))} >
                {addLoad ? (<Loader />) : (<svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16" > <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" /> </svg>)}{" "}
                {addLoad ? "Loading" : value.isUpdate ? "Update Product" : "Add Product"}
              </button>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
