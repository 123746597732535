import React, { useEffect, useState } from "react";
import { Loader } from '../../components/utilities/loader';
import { Link } from 'react-router-dom';
import DataTable from '../../components/utilities/table'
import { useDispatch, useSelector } from 'react-redux';
import { GetAllCpeList } from '../../store/devices/deviceService';
import { IoReloadSharp } from 'react-icons/io5';
import axios from "axios";
import { URL } from "./conf";
import Tabs from "./layout";

export default function Basestation() {
  const dispatch = useDispatch();
  const { cpeData, loading } = useSelector(state => state.cpeList);
  const [loader, setLoading] = useState(false)
  const [modal, setModal] = useState({ enableModal: false, enableData: {}, viewData: {}, viewModal: false });
  const [bsList, setBsList] = useState({});
  const [data, setData] = useState({});
  // console.log('bs', loading, cpeData[0]?.macAddress);

  useEffect(() => { dispatch(GetAllCpeList()); }, []);

  useEffect(() => { getData(); }, [cpeData]);

  const getData = () => {
    const macs = cpeData.map((val) => val.macAddress)
    let promiseArray = macs.map(v => axios.get(`${URL}basestation.php?page=bs&mac=${v}`));
    return Promise.all(promiseArray).then(results => {
      if (results && results.length > 0) {
        console.log('results', results.length);
        const tableData = results.map((res, i) => {
          const cpeItems = cpeData[i];
          const responseItems = res.data.list[0];
          console.log('res', cpeItems, responseItems);
          return {
            no: i + 1,
            serialNumber: cpeItems.serialNum,
            modalName: cpeItems.name,
            configuration: cpeItems.config,
            storeName: cpeItems.storeName,
            id: cpeItems.storeId,
            ipAddress: cpeItems.ipAddress,
            macAddress: cpeItems.macAddress,
            status: responseItems ? StatusCode[responseItems.status] : 'Not found',
          }
        })
        setData(tableData);

      }

    })
  }


  const header = [
    { headerName: '#', field: 'no', editable: false, resizable: true, maxWidth: 10 },
    { headerName: 'Device ID', field: 'serialNumber', editable: false, resizable: true, },
    { headerName: 'Model Name', field: 'modalName', editable: false, resizable: true, },
    { headerName: 'IP Address', field: 'ipAddress', editable: false, resizable: true, },
    { headerName: 'MAC Address', field: 'macAddress', editable: false, resizable: true, },
    { headerName: 'Status', field: 'status', editable: false, resizable: true },
    // { headerName: 'Port', field: 'port', editable: false, resizable: true, },
    // { headerName: 'Tags', field: 'tags', editable: false, resizable: true },
    {
      headerName: 'Tags', editable: false, minWidth: 200, cellRendererFramework: (param) => {
        return (
          <React.Fragment>
            <Link to={'tags/' + param.data.macAddress}><button className='btn btn-sm btn-outline-primary mr-2' >View Tags</button></Link>
          </React.Fragment>
        )
      }
    },

  ];
  const StatusCode = {
    '5001': 'Online',
    '5002': 'Offline',

  }
  return (
    <div>
      <div className="row d-flex align-items-center mb-3 mb-md-2">
        <div className="col-md-6">
          <p className="medium ft-23 mb-2 mb-md-0">
            Base Station
            <Link to='/basestation' > <IoReloadSharp onClick={() => getData()} /></Link>
            {loading || loader ? <Loader /> : null}
          </p>
        </div>
        <Tabs />
      </div>
      <div className="row d-flex justify-content-end">
        {data.length > 0 ? <DataTable key={data} headers={header} data={data} pagination={true} globalSearch={false} /> : null}
      </div>
    </div>



  )
}



// const viewHandle = (data) => {
//   setModal(modal => ({ ...modal, viewData: data, viewModal: true }));
// }

// const closeHandle = (data) => {
//   setModal(modal => ({ ...modal, enableModal: false, enableData: {}, viewData: {}, viewModal: false }));
// }

// const bsAPI = async (value, ind) => {
// const rdata = [...data]
// // const index = rdata.findIndex((val) => val.macAddress === mac);
// console.log('index', ind);
// let bs = await (axios({
//   method: 'get',
//   url: URL + 'bs&mac=' + value.macAddress,
//   timeout: 3000,
// }).then(res => {
//   if (res.data) {
//     if (res.data.list.length > 0) {
//       rdata[index].status = StatusCode[res.data.list[0].status]
//       // setData([...rdata])
//       return StatusCode[res.data.list[0].status];
//     }
//     return 'Not found';
//   }
// }).catch(err => {
//   return 'Connection error'
//   // setBsList(bs)
//   // setLoading(false)
//   // console.log(err.response.data)
// }))
// return bs;
// }

// const getBsList = async () => {
//   console.log('-------------getBsList');
//   setLoading(true);
//   // setBsList({})
//   let mac = ''

//   let statusData = data.map((val, i) => {
//     mac = val.macAddress;
//     let bs = bsAPI(mac);
// return {
//   no: i + 1,
//   serialNumber: val.serialNum,
//   modalName: val.name,
//   configuration: val.config,
//   storeName: val.storeName,
//   id: val.storeId,
//   ipAddress: val.ipAddress,
//   macAddress: val.macAddress,
//   status: bs,
// };
//   });
//   console.log('====================statusData', statusData);
//   return statusData;
//   // return bs;


// };
// console.log('bslist', bsList);

// console.log(refresh);