import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import Sidebar from './pages/layout/sidebar';
import Forgot from './pages/login/forgot';
import Login from './pages/login/login';
import Footer from './pages/layout/footer';
import AddEslTag from './pages/esltags/addEslTag';
import AddUser from './pages/users/addUser';
import ListUsers from './pages/users/listUsers';
import EslTagsList from './pages/esltags/eslTagsList';
import Reset from './pages/login/reset';
import AddStore from './pages/store/addStore';
import ListStore from './pages/store/storeList';
import { AdminRoles } from './components/utilities/functions';
import ListDevice from './pages/devices/deviceList';
import AddProduct from './pages/products/addProduct';
import ListProduct from './pages/products/productList';
import ProductBinding from './pages/products/productBinding';
import ListProductBinding from './pages/products/productBindingList';
import ListTemplate from './pages/template/templateList';
import EditTemplate from './pages/template/editTemplate';
import Squarepos from './pages/squarepos/squarepos';
import SquareposSuccess from './pages/squarepos/squarepossuccess';
import Basestation from './pages/basestation';
import Tags from './pages/basestation/tags';

const Routers = () => {
    const isLoggedIn = localStorage.getItem('customer.token');
    const { userData } = useSelector((state) => state.login);
    const [isToggle, setIsToggle] = useState(true);

    if (isLoggedIn) {
        return (
            <BrowserRouter>
                <div className="wrapper">
                    <Sidebar isToggle={isToggle} toggle={() => setIsToggle(!isToggle)} />
                    <div className={isToggle ? "main" : "main open1"}>
                        <div className="main-content">
                            <Routes>
                                <Route path='/template-library' element={<ListTemplate />} />
                                <Route path='/edit-template/:id' element={<EditTemplate />} />

                                {/* <Route path='/template-add' element={<AddTemplate />} />
                                <Route path='/template-edit/:id' element={<AddTemplate />} /> */}
                                <Route path='/user-edit/:id' element={<AddUser />} />
                                <Route path='/users-list' element={<ListUsers />} />
                                {AdminRoles.includes(userData?.userType) ?
                                    <Route path='/user-add' element={<AddUser />} />
                                    : null}
                                <Route path='/store-list' element={<ListStore />} />
                                <Route path='/store-edit/:id' element={<AddStore />} />
                                <Route path='/store-add' element={<AddStore />} />
                                <Route path='/product-binding-list' element={<ListProductBinding />} />
                                <Route path='/product-binding-edit/:id' element={<ProductBinding />} />
                                <Route path='/product-binding' element={<ProductBinding />} />
                                <Route path='/product-list' element={<ListProduct />} />
                                <Route path='/product-edit/:id' element={<AddProduct />} />
                                <Route path='/product-edit-grouped' element={<AddProduct />} />
                                <Route path='/product-add' element={<AddProduct />} />
                                <Route path='/square-pos' element={<Squarepos />} />
                                <Route path='/authenticate/squarepos' element={<SquareposSuccess />} />
                                <Route path='/base-station' element={<ListDevice />} />
                                {/* <Route path='/device-add' element={<Dashboard />} /> */}
                                <Route path='/esl-tag-edit/:id' element={<AddEslTag />} />
                                <Route path='/esl-tag-list' element={<EslTagsList />} />
                                <Route path='/esl-tag-add' element={<AddEslTag />} />
                                <Route path='/' element={<Dashboard />} />
                                <Route path="*" element={<Navigate to="/" replace />} />

                                <Route path='/basestation' element={<Basestation />} />
                                <Route path='/basestation/tags/:mac' element={<Tags />} />
                                <Route path='/basestation/tags/' element={<Basestation />} />
                            </Routes>
                        </div>
                        <Footer />
                    </div>
                </div>
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/forgot" element={<Forgot />} />
                    <Route path="/reset/token/:token" element={<Reset />} />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
            </BrowserRouter>
        );
    }
};

export default Routers;
