import React, { useEffect, useState } from "react";
import { Loader } from '../../components/utilities/loader';
import { Link } from 'react-router-dom';
import DataTable from '../../components/utilities/table'
import { IoReloadSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import Tabs from "./layout";
import axios from "axios";
import { URL } from "./conf";
import { useParams } from "react-router-dom";
import qs from 'qs';

// export const TagsListUrl = URL + '/MWTag/tag/getList'
// export const BSListUrl = URL + '/MWTag/coor/getList'

export default function Tags() {
  const [state, setState] = useState({});
  // const [modal, setModal] = useState({ enableModal: false, enableData: {}, viewData: {}, viewModal: false });
  const [tagsList, setTagsList] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { cpeData } = useSelector(state => state.cpeList);
  const { mac } = useParams();


  useEffect(() => { getTagsList() }, [])

  const getTagsList = () => {
    setLoading(true);
    setTagsList({});

    // let mac;
    // if (cpeData[0]) {
    //   mac = cpeData[0].macAddress;
    // }

    axios({
      method: 'post',
      url: URL + 'basestation.php?page=tags&mac=' + mac,
      timeout: 3000,
    }).then(res => {
      let data = [];
      let n = 1;
      if (res.data) {
        if (res.data.list.length > 0) {
          res.data.list.map((v) => {
            v.status = StatusCode[v.status];
            v.size = Size[v.size];
            // console.log('time', v.updateTime, duration(v.updateTime));
            v.updateTime = duration(v.updateTime);
            v.no = n;
            n++;
            data.push(v)
          })
        } else {
          dispatch({ type: 'ALERT_ERROR', payload: { message: 'Tags list is empty', duration: 4000 } });

        }
      }
      setTagsList(res.data.list)
      setLoading(false)

    }).catch(err => {
      let msg = { type: 'ALERT_ERROR', payload: { message: "Unable to connect BaseStation", duration: 4000 } };
      if (err.response?.status === 404) {
        dispatch(msg);
      }
      else if (err.response) {
        dispatch({ type: 'ALERT_ERROR', payload: { message: err.response.data, duration: 3000 } });
      } else {
        dispatch(msg);
      }

      setLoading(false)
      // console.log(err.response.data)
    })

  };

  const TestImage = (tag) => {
    let w = width[tag.size];
    console.log(w.width, w.height, tag.identify);
    // setLoading(true);
    let postData = {
      // ?text=' + tag.identify + '&time=' + Date.now() + '&width=' + w.width + '&height=' + w.height + '&mac=' + mac,
      text: tag.identify,
      time: Intl.DateTimeFormat().resolvedOptions().timeZone,
      width: w.width,
      height: w.height,
      mac: mac,
    }
    axios({
      method: 'POST',
      url: URL + 'imagepush.php',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(postData),
      timeout: 3000,
    }).then(res => {
      setLoading(true);
      //wait for 1 sec
      setTimeout(() => {
        getTagsList();
        setLoading(false);
      }, 3000);
      // console.log('Push image', res.data);
    }).catch(err => {
      console.log('Push image error', err);
    })



  }
  // console.log('tagsList', tagsList);

  const StatusCode = {
    '3001': 'Online',
    '3002': 'Updating',
    '3003': 'Update_succeed',
    '3005': 'Update_failed',
    '3006': 'Offline',
  }
  const Size = {
    '9004': '1.54(LR)',
    '9008': '1.54',
    '9001': '2.13(LR)',
    '9007': '2.13',
    '9009': '2.66',
    '9002': '2.9',

    '9003': '4.2',
    '9012': '5.83',

    '9005': '7.73',
    '9011': '7.5',
    '9006': '7.5',
    '9010': '10.2',
  }
  const width = {
    1.54: { width: 200, height: 200 },
    2.13: { width: 250, height: 120 },
    2.66: { width: 296, height: 152 },
    2.9: { width: 296, height: 128 },

    4.2: { width: 400, height: 300 },
    5.4: { width: 648, height: 480 },
    5.83: { width: 648, height: 480 },
    7.5: { width: 800, height: 480 },
    10.2: { width: 960, height: 640 },

  }

  const statusLable = (st) => {
    return (<span class="badge bg-warning text-dark">{st}</span>)
  }

  const header = [
    { headerName: '#', field: 'no', editable: false, resizable: true, maxWidth: 70 },
    { headerName: 'ID', field: 'identify', editable: false, resizable: true, },
    { headerName: 'Size', field: 'size', editable: false, resizable: true },
    // { headerName: 'Status', field: 'status', editable: false, resizable: true },
    {
      headerName: 'Status', field: 'status', editable: false, sortable: true, sort: 'desc', cellRendererFramework: (param) => {
        return (
          <React.Fragment>
            {param.data.status == 'Updating' && (<span class="badge bg-warning text-dark">Updating</span>)}
            {param.data.status == 'Online' && (<span class="badge bg-info ">Online</span>)}
            {param.data.status == 'Update_succeed' && (<span class="badge bg-success">Updated</span>)}
            {param.data.status == 'Update_failed' && (<span class="badge bg-danger">Failed</span>)}
            {param.data.status == 'Offline' && (<span class="badge bg-secondary">Offline</span>)}


          </React.Fragment>
        )
      }
    },
    { headerName: 'Power', field: 'power', editable: false, resizable: true, },
    { headerName: 'Uptime', field: 'updateTime', editable: false, resizable: true, },
    {
      headerName: 'Test Image', editable: false, minWidth: 200, cellRendererFramework: (param) => {
        return (
          <React.Fragment>
            <img className='pointer icon-padding hovers' src={'/img/up.png'} alt={'info'} width={22} onClick={() => TestImage(param.data)} />
          </React.Fragment >
        )
      }
    },

  ];

  console.log('Time', duration('2024-08-07 07:00:00'));

  function duration(t0) {
    let d = (new Date()) - (new Date(t0));
    let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
    let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
    let minutes = Math.floor(d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60);
    let t = {};
    ['weekdays', 'days', 'hours', 'minutes'].forEach(q => { if (eval(q) > 0) { t[q] = eval(q); } });
    // let t = days + 'd ' + hours + 'h ' + minutes + 'm';
    //combine all
    t = Object.keys(t).map((k) => t[k] + k[0]).join(' ');
    return t;
  }
  // const viewHandle = (data) => {
  //   setModal(modal => ({ ...modal, viewData: data, viewModal: true }));
  // }

  // const closeHandle = (data) => {
  //   setModal(modal => ({ ...modal, enableModal: false, enableData: {}, viewData: {}, viewModal: false }));
  // }

  return (
    <div>
      <div className="row d-flex align-items-center mb-3 mb-md-2">
        <div className="col-md-6">
          <p className="medium ft-23 mb-2 mb-md-0">Base Station
            <IoReloadSharp onClick={() => getTagsList()} />
            {loading ? <Loader /> : null}</p>
        </div>
        <Tabs />
      </div>
      <div className="row d-flex justify-content-end">
        {!loading && tagsList.length > 0 && <DataTable key={tagsList} headers={header} data={tagsList} pagination={true} globalSearch={true} />}
      </div>

    </div>
  )
}

