import axios from "axios";
import { useEffect } from "react";
import { useJwt } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import Toast from "./components/toast";
import Routers from "./Routes";
import { UserLogout } from "./store/login/loginService";

function App() {
  const dispatch = useDispatch();
  let token = localStorage.getItem('customer.token');
  const { isExpired } = useJwt(token);

  //if (isExpired) {
  //  dispatch(UserLogout());
  //}

  // const checkAuth = ['/login', '/forgot'];
  axios.interceptors.request.use(request => {
    // const isAuth = checkAuth.includes(window.location.pathname);
    // let token = userData.jwttoken;
    let token = localStorage.getItem('customer.token');
    // if (!isAuth && userData.jwtToken) {
    request.headers['Authorization'] = `Bearer ${token ?? '123'}`;
    request.headers['Access-Control-Allow-Origin'] = `*`;
    // }
    return request;
  });

  return (
    <>
      <Toast />
      <Routers />
    </>
  );
}

export default App;
