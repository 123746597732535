import { Types } from "../types";

let cpeInitial = {
    loading: false,
    cpeData: []
}

export const cpeListReducer = (state = cpeInitial, action) => {
    switch (action.type) {

        case Types.CPE_REQUEST:
            return {
                ...state,
                loading: true,
                cpeData: []
            }

        case Types.CPE_SUCCESS:
            return {
                ...state,
                loading: false,
                cpeData: action.payload ?? []
            }

        case Types.CPE_ERROR:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}