import axios from 'axios';
import { baseUrl } from '../API';
import { Types } from "../types";

export const getStoreList = () => {

    return (dispatch) => {
        dispatch({ type: Types.STORE_REQUEST });
        axios.get(baseUrl + '/cpe/getAllStoresByCustomer').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.STORE_SUCCESS, payload: resp.data });
            }
        }).catch(err => {
            dispatch({ type: Types.STORE_ERROR });

        });
    }
};

export const StoreAdd = (data) => {

    return (dispatch) => {
        dispatch({ type: Types.STORE_ADD_REQUEST });
        return axios.post(baseUrl + '/cpe/addStore', data).then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: `${data.storeName} ${data.storeId ? 'Updated' : 'Added'}` } });
                dispatch({ type: Types.STORE_ADD_SUCCESS });
            } else if (resp.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: resp.data.message } });
                dispatch({ type: Types.USER_ADD_ERROR });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Store Add Failed' } });
                dispatch({ type: Types.STORE_ADD_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.STORE_ADD_ERROR });
            if (err.response?.data?.error) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            } else if (err.response?.data?.message) {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.message } });
            } else {
                dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Store Add Failed' } });
            }
            return err;
        });
    }
};
export const getUnAllocatedCPE = () => {

    return (dispatch) => {
        dispatch({ type: Types.UN_ALLOCATED_CPE_REQUEST });
        axios.get(baseUrl + '/cpe/getUnAllocatedCPEsByCustomer').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.UN_ALLOCATED_CPE_SUCCESS, payload: resp.data });
            }
        }).catch(err => {
            dispatch({ type: Types.UN_ALLOCATED_CPE_ERROR });

        });
    }
};