import React, { useEffect, useState } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Input from '../../components/form/input';
import Select from '../../components/form/select';
import { Loader } from '../../components/utilities/loader';
import { handleValidation } from '../../components/utilities/validations';
import { EslAdd, getESLSize } from '../../store/esltags/eslService';
import { getStoreList } from '../../store/store/storeService';
import { conf } from "../../conf";


export default function AddEslTag() {

    const [state, setState] = useState({ barData: {}, barModal: false, serialNumber: '', screenSize: '', store: '' });
    const [value, setValue] = useState({ viewPassword: false, isUpdate: false, storeList: [], sizeList: [] });
    const [errors, setError] = useState({});
    const { addLoad, sizeLoad, sizeData, eslData } = useSelector(state => state.eslList);
    const { loading, storeData } = useSelector(state => state.stores);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        dispatch(getStoreList());
        dispatch(getESLSize());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const data = storeData.map((v) => {
            return {
                key: v.storeId,
                name: v.storeName
            }
        });
        setValue(value => ({ ...value, storeList: data }));
    }, [storeData])

    useEffect(() => {
        let size = Object.keys(sizeData).map((key) => key);
        const data = size.map(v => {
            return {
                key: v,
                name: v
            }
        })
        setValue(value => ({ ...value, sizeList: data }));
    }, [sizeData])

    useEffect(() => {
        if (id) {
            let esl = eslData.find(o => o.tagId === id);
            setState(state => ({ ...state, serialNumber: esl?.serialNo, screenSize: esl?.screenSize, store: esl?.storeId }))
            setValue(value => ({ ...value, isUpdate: true }));
        } else {
            setValue(value => ({ ...value, isUpdate: false }));
            setState(state => ({ ...state, serialNumber: "", screenSize: "", store: "" }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleBarCode = (data) => {
        const serial = data.text.substring(data.text.length - 10);
        setState({ ...state, barModal: false, barData: data, serialNumber: serial });
    }

    const handleChange = (e) => {
        e.persist();
        setState(state => ({ ...state, [e.target.name]: e.target.value }));
    }

    const handleSubmit = (e) => {
        e?.preventDefault();
        let conditions = [
            { condition: 'required', name: 'serialNumber', value: state.serialNumber, text: 'Serial Number' },
            { condition: 'required', name: 'screenSize', value: state.screenSize, text: 'Screen Size' },
            { condition: 'required', name: 'store', value: state.store, text: 'Store' },
        ]
        let errors = handleValidation(conditions);
        if (!errors.isError) {
            setError({});
            let data = {
                "screenSize": state.screenSize,
                "serialNo": state.serialNumber,
                "storeId": state.store,
                "tagId": id ?? 0
                // customerId: 1
            }

            dispatch(EslAdd(data)).then(resp => {
                if (resp.status === 200 || resp.status === 201) navigate('/esl-tag-list');
            });

        } else {
            setError(errors.errors)
        }
    }

    return (
        <div>
            <div>
                <div className="row d-flex align-items-center mb-3 mb-md-5">
                    <div className="col-md-6">
                        <p className="medium ft-23 mb-2 mb-md-0">{value.isUpdate ? 'Edit' : 'Add'} {conf.esltag.esltag}</p>
                    </div>
                    <div className="col-md-6 text-start text-md-end">
                    </div>
                </div>
                {state.barModal ?
                    <BarcodeScannerComponent
                        width={500}
                        height={200}
                        onUpdate={(err, result) => {
                            if (result) {
                                handleBarCode(result);
                            }
                        }}
                    />
                    : null}
                <div className="row d-flex justify-content-center">
                    <div className="col-12 col-lg-10">
                        <div className="row">
                            <div style={{ display: 'flex', width: "100%", alignItems: "center" }}>
                                <div className="col-11 col-md-11">
                                    <Input className="form-control" type='text' value={state.serialNumber} name='serialNumber' placeholder='Enter Serial Number' onchange={handleChange} label={'Serial Number'} autoFocus={true} error={errors?.serialNumber} required />
                                </div>
                                <div>
                                    <span onClick={() => state.barModal ? setState({ ...state, barModal: false }) : setState({ ...state, barModal: true })} className="input-group-text"><i style={{ padding: '7.75px' }} className='fa fa-camera' /></span>
                                </div>
                            </div>

                            <div className="col-12 col-md-12">
                                {/* <Input type='text' value={state.screenSize} name='screenSize' placeholder='Enter Screen Size' onchange={handleChange} label={'Screen Size'} error={errors?.screenSize} required /> */}
                                <Select name='screenSize' value={state.screenSize} label={'Screen Size'} onchange={handleChange} options={value.sizeList} error={errors?.screenSize} required loading={sizeLoad} />
                            </div>
                            <div className="col-12 col-md-12">
                                <Select name='store' value={state.store} label={'Site'} onchange={handleChange} options={value.storeList} error={errors?.store} required loading={loading} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center mt-4 mb-5">
                                <button onClick={(e) => addLoad ? null : handleSubmit(e)} className={`btn btn-${value.isUpdate ? 'warning' : 'theme'}`}> <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16" >
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                </svg> {addLoad ? <Loader /> : null} {value.isUpdate ? "Edit" : "Add"} {conf.esltag.esltag}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
