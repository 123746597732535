import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { handleValidation } from "../../components/utilities/validations";
import { Loader } from "../../components/utilities/loader";
import {
  getProductList,
  productBind,
} from "../../store/product/productService";
import { Required } from "../../components/utilities/required";
import Select from "react-select";
import { getESLList } from "../../store/esltags/eslService";
import { getTemplateList } from "../../store/template/templateService";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { Types } from "../../store/types";
import { POSTagList } from "../../store/common/commonService";
import { conf } from "../../conf";

export default function ProductBinding() {
  const templates = useSelector((state) => state.templates);
  const productStore = useSelector((state) => state.products);
  const eslStore = useSelector((state) => state.eslList);

  const [state, setState] = useState({
    productTagMapId: "",
    productId: "",
    deviceTagId: "",
    templateId: "",
  });
  const [value, setValue] = useState({
    viewPassword: false,
    isUpdate: false,
    productList: [],
    posTagList: [],
    deviceTagList: [],
    templateList: [],
    nop: 0,
  });
  const [error, setError] = useState({});
  const [scan, setScan] = useState({
    device: false,
    deviceData: {},
    product: false,
    productData: {},
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFav, setIsFav] = useState(false);
  const [squarePOS, setSquarePOS] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getProductList());
    dispatch(getESLList());
    dispatch(getTemplateList())
      .then((resp) => {
        if (resp.status === 200) {
          getTemplates(resp.data);
        }
      })
      .catch((err) => { })
      .finally((resp) => {
        console.log("---->>>>", resp);
      });
    // dispatch(POSTagList()).then((resp) => {
    //   if (resp.data?.body && resp.data?.body?.length > 0) {
    //     let data = [];
    //     resp.data.body.map((v, i) => {
    //       if (v.itemID) {
    //         data.push({
    //           value: v.itemID,
    //           label: v.itemName,
    //         });
    //       }
    //       return null;
    //     });
    //     setValue((value) => ({ ...value, posTagList: data }));
    //   }
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState((state) => ({ ...state, productId: [] }));
  }, [squarePOS]);

  useEffect(() => {
    if (eslStore.eslData && eslStore.eslData.length > 0) {
      let data = [];
      eslStore.eslData.map((v, i) => {
        if (!v.isMappedWithProduct) {
          data.push({
            value: v.tagId,
            label: `${v.serialNo} (${v.screenSize})`,
            text: v.serialNo,
            inch: v.screenSize,
          });
        }
        return null;
      });
      setValue((value) => ({ ...value, deviceTagList: data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eslStore.eslData]);

  useEffect(() => {
    if (id) {
      let bind = productStore.productBindList?.filter((o) => o.tagId === id);
      const devices = eslStore.eslData.find(
        (o) => o.tagId === bind?.[0]?.tagId
      );
      console.log("DEVICES ", devices, eslStore.eslData, value.deviceTagList);
      setState((state) => ({
        ...state,
        deviceTagId: {
          value: bind?.[0]?.tagId,
          label: `${devices?.serialNo} (${devices?.screenSize})`,
          inch: devices?.screenSize,
          text: devices?.serialNo,
        },
        templateId: bind?.[0]?.templateId,
        productTagMapId: bind?.[0]?.productTagMapId,
      }));
      setTimeout(() => {
        if (bind.length) {
          const productsList = productStore.productData;
          const prds = [];
          productsList.forEach((v, i) => {
            const find = bind.find((o) => o.productId === v.productId);
            if (find) {
              prds.push({
                value: find?.productId,
                label: find?.productName,
              });
            }
          });
          setState((state) => ({ ...state, productId: prds }));
        }
      }, 100);
      const temp = templates.templateData.find(
        (o) => o.templateId === bind?.[0]?.templateId
      );
      // setTimeout(() => {
      // setNop(temp?.noOfProduct ?? 0);
      // setValue(value => ({ ...value, nop: temp?.noOfProduct ?? 0 }));
      // }, 200);
      console.log("NOPPPP ", temp, templates.templateData);
      setValue((value) => ({
        ...value,
        isUpdate: true,
        nop: temp?.noOfProduct ?? 0,
      }));
    } else {
      setValue((value) => ({ ...value, isUpdate: false }));
      setState((state) => ({
        ...state,
        productTagMapId: "",
        productId: "",
        deviceTagId: "",
        templateId: "",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, eslStore.eslData, templates.templateData, productStore.productData]);

  // useEffect(() => {
  //     if (productStore.productData && productStore.productData.length > 0) {
  //         let data = productStore.productData.map((v, i) => {
  //             return {
  //                 value: v.productId,
  //                 label: v.productName,
  //                 text: v.barCode,
  //             }
  //         });
  //         setValue(value => ({ ...value, productList2: data }));
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [productStore.productData])

  useEffect(() => {
    console.log("THIS CALLED ", state.deviceTagId);
    if (state.deviceTagId) {
      const storeId = eslStore.eslData?.find(
        (o) => o.tagId === state.deviceTagId?.value
      );
      const products = productStore.productData?.filter(
        (o) => o.storeId === storeId?.storeId
      );
      if (products && products.length) {
        const data = products.map((val) => {
          return {
            value: val.productId,
            label: val.productName,
          };
        });
        setValue((value) => ({ ...value, productList: [...data] }));
        if (!id) setState((state) => ({ ...state, productId: "" }));
      } else {
        setValue((value) => ({ ...value, productList: [] }));
        if (!id) setState((state) => ({ ...state, productId: "" }));
      }

      const templateList = templates.templateData;
      console.log("TEMPLATE ", templateList);
      if (state.deviceTagId?.inch) {
        console.log("INCH ", state.deviceTagId);
        const deviceSize = state.deviceTagId?.inch?.replace("inch", "")?.trim();
        const templatesSizeList = templateList.filter(
          (o) => o.templateInfo?.replace("inch", "").trim() === deviceSize
        );
        console.log("FILTERS ", deviceSize, templatesSizeList);
        setValue((value) => ({
          ...value,
          templateList: [...templatesSizeList],
        }));
        if (id) filterTemplateOnProducts(templatesSizeList);
      }
    } else {
      setValue((value) => ({ ...value, templateList: templates.templateData }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.deviceTagId,
    templates.templateData,
    id,
    productStore.productData,
    templates.templateData,
    value.nop,
    eslStore.eslData,
  ]);
  console.log("NOP ", value.nop);
  const filterTemplateOnProducts = (templates) => {
    const tdata = templates.filter((o) => o.noOfProduct === value.nop);
    console.log("WHAT ", tdata, templates, value.nop);
    setValue((value) => ({ ...value, templateList: [...tdata] }));
  };

  const getTemplates = (data) => {
    setValue((value) => ({ ...value, templateList: [...data] }));
  };

  const handleSelectChange = (name, value) => {
    setState((state) => ({ ...state, [name]: value }));
    if (name === "deviceTagId") {
      setValue((value) => ({ ...value, nop: 0 }));
      setState((state) => ({ ...state, productId: "" }));
    }
  };

  const selectTemplate = (tid) => {
    if (!id) setState((state) => ({ ...state, productId: "" }));
    setState((state) => ({ ...state, templateId: tid }));
    const templateFile = templates.templateData.find(
      (o) => o.templateId === tid
    );
    setValue((value) => ({ ...value, nop: templateFile?.noOfProduct }));
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    let conditions = [
      {
        condition: "required",
        name: "templateId",
        value: state.templateId,
        text: "Template",
      },
      {
        condition: "required",
        name: "productId",
        value: state.productId,
        text: "Product",
      },
      {
        condition: "required",
        name: "deviceTagId",
        value: state.deviceTagId,
        text: "Device Tag ID",
      },
    ];
    let errors = handleValidation(conditions);

    if (value.nop !== state.productId?.length) {
      errors.errors["productId"] = "Select Required Products";
      errors.isError = true;
    }
    if (!errors.isError) {
      setError({});
      const dataArray = state.productId.map((v, index) => {
        const filter = productStore.productBindList
          .filter((o) => o.tagId === state.deviceTagId?.value)
          .sort((a, b) => a.position - b.position);
        let data = {
          productId: v.value,
          productTagMapId: filter[index]?.productTagMapId ?? null,
          tagId: state.deviceTagId?.value,
          templateId: state.templateId,
          isSquareProduct: squarePOS,
        };
        return data;
      });
      dispatch(productBind(dataArray)).then((resp) => {
        if (resp.status === 200) navigate("/product-binding-list");
      });
    } else {
      setError(errors.errors);
    }
  };

  const handleBarCode = (data, tag, isProduct, productList) => {
    if (isProduct) {
      const selected = productList.find((e) => e.text === data.text);
      if (selected) {
        handleSelectChange("productId", selected);
      } else {
        dispatch({
          type: Types.ALERT_ERROR,
          payload: { message: "No Product Found", duration: 2000 },
        });
        handleSelectChange("productId", "");
      }
    } else {
      const scanData = data.text.substring(data.text.length - 10);
      const selected = tag.find((e) => e.text === scanData);
      if (selected) {
        handleSelectChange("deviceTagId", selected);
      } else {
        dispatch({
          type: Types.ALERT_ERROR,
          payload: { message: "No Device Found", duration: 2000 },
        });
        handleSelectChange("deviceTagId", "");
      }
    }
    setScan((scan) => ({ ...scan, device: false, product: false }));
  };
  console.log("first ", templates, value, state);
  return (
    <>
      <div className="row d-flex align-items-center mb-3 mb-md-5">
        <div className="col-md-6" style={{ width: "80%" }}>
          <p className="medium ft-23 mb-2 mb-md-0">{conf.binding.binding}</p>
        </div>
        {/* <div
          className="form-check form-switch align-items-end"
          style={{ width: "20%" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={squarePOS}
            onClick={(e) => setSquarePOS(!squarePOS)}
          ></input>
          <label className="form-check-label" for="flexSwitchCheckDefault">
            SquarePOS
          </label>
        </div> */}
        <div className="col-md-6 text-start text-md-end"></div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-lg-10">
          {scan.device ? (
            <Fragment>
              <label className="mb-2">
                {!scan.product ? "Scan Device Tag ID" : "Scan Product"}
              </label>
              <BarcodeScannerComponent
                width={500}
                height={200}
                onUpdate={(err, result) => {
                  if (result) {
                    handleBarCode(
                      result,
                      value.deviceTagList,
                      scan.product,
                      value.productList
                    );
                  }
                }}
              />
            </Fragment>
          ) : null}
          <div className="row">
            <div className="col-6 col-md-6">
              <div className="input-group">
                <label className="mb-2">
                  {conf.binding.tagId} <Required />{" "}
                  {eslStore.loading ? <Loader /> : null}{" "}
                  {error.deviceTagId ? (
                    <span className="text-danger floatRight">
                      {error.deviceTagId}
                    </span>
                  ) : null}
                </label>
                <div style={{ display: "flex", width: "100%" }}>
                  <Select
                    name="deviceTagId"
                    value={state.deviceTagId}
                    onChange={(value) =>
                      handleSelectChange("deviceTagId", value)
                    }
                    options={value.deviceTagList}
                    isDisabled={id}
                  />
                  <div className="input-group-prepend pointer">
                    <span
                      onClick={() =>
                        setScan((scan) => ({
                          ...scan,
                          device: !scan.device,
                          product: false,
                        }))
                      }
                      className="input-group-text"
                    >
                      <i
                        style={{ padding: "5px 0px 3px 0px" }}
                        className="fa fa-camera"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-6boo">
              <div className="input-group">
                <label className="mb-2">
                  {conf.binding.lot} <Required />{" "}
                  {productStore.loading ? <Loader /> : null}{" "}
                  {error.productId ? (
                    <span className="text-danger floatRight">
                      {error.productId}
                    </span>
                  ) : null}{" "}
                  {value.nop
                    ? `(Select ${value.nop} Product's)`
                    : "(Select Template)"}
                </label>
                <div style={{ display: "flex", width: "100%" }}>
                  <Select
                    name="productId"
                    value={state.productId}
                    isMulti={true}
                    onChange={(val) =>
                      val.length <= value.nop
                        ? handleSelectChange("productId", val)
                        : null
                    }
                    options={squarePOS ? value.posTagList : value.productList}
                    isDisabled={!value.nop}
                  />
                  <div className="input-group-prepend pointer">
                    <span
                      onClick={() =>
                        setScan((scan) => ({
                          ...scan,
                          device: !scan.device,
                          product: true,
                        }))
                      }
                      className="input-group-text "
                    >
                      <i
                        style={{ padding: "5px 0px 3px 0px" }}
                        className="fa fa-camera"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 mt-4">
              <div className="form-group">
                <div className="d-flex">
                  <label className="mb-2">
                    Select Template <Required />{" "}
                    {templates.loading ? <Loader /> : null}{" "}
                    {error?.templateId ? (
                      <span className="text-danger floatRight">
                        {error.templateId}
                      </span>
                    ) : null}{" "}
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      onClick={() => setIsFav(!isFav)}
                      type="checkbox"
                      checked={isFav}
                    />
                    <label className="form-check-label">Favorite</label>
                  </div>
                </div>

                <div className="border p-3 br-8">
                  <div className="row">
                    {!templates.loading
                      ? value.templateList.length
                        ? value.templateList.map((v, i) => {
                          return v.favorite ? (
                            <div className="col-md-4" key={i}>
                              <span style={{ fontSize: "12px" }}>
                                {v?.templateInfo}
                              </span>
                              <img
                                onClick={() => selectTemplate(v.templateId)}
                                src={`data:image/png;base64,${v.templateImage}`}
                                className={`img-fluid cardbox ${state.templateId === v.templateId
                                  ? "active"
                                  : ""
                                  }`}
                                alt="img"
                                width={v.basicTemplate ? 250 : 120}
                              />
                            </div>
                          ) : !isFav ? (
                            <div className="col-md-4" key={i}>
                              <span style={{ fontSize: "12px" }}>
                                {v?.templateInfo}
                              </span>
                              <img
                                onClick={() => selectTemplate(v.templateId)}
                                src={`data:image/png;base64,${v.templateImage}`}
                                className={`img-fluid cardbox ${state.templateId === v.templateId
                                  ? "active"
                                  : ""
                                  }`}
                                alt="img"
                                width={v.basicTemplate ? 250 : 120}
                              />
                            </div>
                          ) : null;
                        })
                        : "No Templates"
                      : "Templates Loading"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center mt-4 mb-5">
              <button
                className={`btn ${value.isUpdate ? "btn-warning" : "btn-theme"
                  }`}
                onClick={(e) =>
                  productStore.bindAddLoad ? null : handleSubmit(e)
                }
              >
                {productStore.bindAddLoad ? (
                  <Loader />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={26}
                    height={26}
                    fill="currentColor"
                    className="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                )}{" "}
                {productStore.bindAddLoad
                  ? "Loading"
                  : value.isUpdate
                    ? "Update Product"
                    : "Bind Product"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
