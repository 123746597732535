import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CustomModal, ModalList } from "../../components/form/modal";
import { Loader } from "../../components/utilities/loader";
import DataTable from "../../components/utilities/table";
import { Navigation } from "../../store/common/commonService";
import {
  getProductList,
  productDelete,
  productExport,
  productMultipleDelete,
  productUpload,
} from "../../store/product/productService";
import { Types } from "../../store/types";
import { getStoreList } from "../../store/store/storeService";
// import { ProductLabels } from "../../components/utilities/products";
import { conf } from "../../conf";

export default function ListProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileRef = useRef(null);

  const [state, setState] = useState({
    data: [],
    dropItem: 0,
    editSelected: [],
    storeSelected: [],
    clearSearch: false,
    editedData: {},
  });
  const [modal, setModal] = useState({
    deleteModal: false,
    deleteData: {},
    editModal: false,
    editData: [],
    prevModal: "",
    viewData: {},
    viewModal: false,
  });
  const [file, setFile] = useState({ modal: false, file: null });
  const [exports, setExports] = useState({ isExport: false, data: "" });

  const { loading, addLoad, deleteLoad, exportLoading, productData } =
    useSelector((state) => state.products);
  const { storeData } = useSelector((state) => state.stores);

  useEffect(() => {
    dispatch(getProductList());
    dispatch(getStoreList());
    dispatch({ type: Types.PRODUCT_EDIT_GROUPED });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modal.editData) {
      const data = {};
      modal?.editData?.map((v) => {
        return (data[v.storeId] = v.isMappedWithTag);
      });
      setState((state) => ({ ...state, editedData: data }));
    }
  }, [modal.editData]);

  useEffect(() => {
    if (productData) {
      let groupedData = groupBy(productData, function (item) {
        // return [item.price, item.category, item.quantity];
        return [
          item.productName,
          item.barCode,
          item.price,
          item.category,
          item.quantity,
        ];
      });
      const data = groupedData?.map((val, i) => {
        let stores = val.map((o) => o.storeName);
        return {
          no: i + 1,
          barCode: val[0].barCode,
          productName: val[0].productName,
          category: val[0].category,
          quantity: val[0].quantity,
          discount: val[0].discount,
          price: val[0].price,
          productId: val[0].productId,
          customerId: val[0].customerId,
          storeId: val[0].storeId,
          storeName: stores,
          userId: val[0].userId,
          nostore: val.length,
          description: val[0].description,
          footnote: val[0].footNote,
          qrCodeUrl: val[0].qrCodeUrl,
          values: val,
        };
      });
      setState((state) => ({ ...state, data: data }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  const header = [
    {
      headerName: "#",
      field: "no",
      editable: false,
      resizable: true,
      maxWidth: 70,
    },
    {
      headerName: conf.product.id,
      field: "barCode",
      editable: false,
      resizable: true,
    },
    {
      headerName: conf.product.name,
      field: "productName",
      editable: false,
      resizable: true,
    },
    {
      headerName: "No. Site",
      field: "nostore",
      editable: false,
      resizable: true,
    },
    {
      headerName: conf.product.storeName,
      field: "storeName",
      editable: false,
      resizable: true,
    },
    {
      headerName: "Category",
      field: "category",
      maxWidth: 150,
      editable: false,
      resizable: true,
    },



  ];

  if (conf.env === 'mfg') header.push({ headerName: "Quantity", field: "quantity", editable: false, resizable: true, },);

  if (conf.env === 'retail') header.push({ headerName: "Price", field: "price", editable: false, resizable: true },);

  header.push(
    {
      headerName: "Action",
      editable: false,
      minWidth: 200,
      cellRendererFramework: (param) => {
        return (
          <React.Fragment>
            {/* {param.data?.values?.length > 1 ? */}
            <>
              <img
                className="pointer icon-padding"
                src={"/img/info.png"}
                alt={"info"}
                onClick={() => viewHandle(param.data)}
                width={22}
              />
              <img
                className="pointer icon-padding"
                src={"/img/edit.png"}
                alt={"info"}
                onClick={() => editModal(param.data)}
                width={22}
              />

              {/* <i className='fa fa-info-circle text-primary icon-padding icon16' onClick={() => viewHandle(param.data)} />
                          <i className='fa fa-edit text-danger icon-padding' onClick={() => editModal(param.data)} /> */}
            </>
            {/* :
                          <>
                              <Link to={'/product-edit/' + param.data.productId} ><i className='fa fa-edit text-primary icon-padding' /></Link>
                              <i className='fa fa-trash text-danger icon-padding' onClick={() => modalHandle(param.data)} />
                          </>
                      } */}

            {/* {param.data?.values?.length > 1 ?
                          <i className='fa fa-trash text-danger icon-padding' onClick={() => editModal(param.data)} /> :
                          <i className='fa fa-trash text-danger icon-padding' onClick={() => modalHandle(param.data)} />} */}
            {/* <button className={`btn btn-sm ${param.data.status ? 'btn-danger' : 'btn-theme'}`} onClick={() => modalHandle(param.data)}>{param.data.status ? <> <i className='fa fa-times' /> Disable </> : <> <i className='fa fa-check' /> Enable</>}</button> */}
          </React.Fragment>
        );
      },
    },

  );

  function arrayFromObject(obj) {
    var arr = [];
    for (var i in obj) {
      arr.push(obj[i]);
    }
    return arr;
  }

  function groupBy(list, fn) {
    var groups = {};
    for (var i = 0; i < list.length; i++) {
      var group = JSON.stringify(fn(list[i]));
      if (group in groups) {
        groups[group].push(list[i]);
      } else {
        groups[group] = [list[i]];
      }
    }
    return arrayFromObject(groups);
  }

  // const modalHandle = (data) => {
  //     setModal(modal => ({ ...modal, deleteData: data, deleteModal: true }));
  // }

  const modalHandleCustom = (data) => {
    setModal((modal) => ({
      ...modal,
      deleteData: data,
      deleteModal: true,
      editModal: false,
      prevModal: modal.editData,
    }));
  };

  const closeHandle = (data) => {
    if (Object.keys(modal.prevModal).length > 0) {
      setModal((modal) => ({
        ...modal,
        deleteModal: false,
        deleteData: {},
        editModal: true,
        editData: modal.prevModal,
        prevModal: {},
      }));
    } else {
      setModal((modal) => ({
        ...modal,
        deleteModal: false,
        deleteData: {},
        editModal: false,
        editData: [],
      }));
      setState((state) => ({ ...state, editSelected: [] }));
    }
  };

  const deleteHandle = () => {
    if (modal.deleteData?.productId) {
      let data = modal.deleteData;
      delete data.values;
      delete data.storeName;
      dispatch(productDelete(data)).then((resp) => {
        if (resp.status === 200) {
          dispatch(getProductList());
          setModal((modal) => ({
            ...modal,
            deleteModal: false,
            deleteData: {},
            editModal: false,
            editData: [],
            prevModal: {},
          }));
          setState((state) => ({ ...state, editSelected: [] }));
        }
      });
    }
  };

  const deleteMultipleHandle = () => {
    let editData = modal.editData;
    let data = editData.filter((item) =>
      state.editSelected.includes(item.storeId)
    );
    if (data.length > 0) {
      dispatch(productMultipleDelete(data)).then((resp) => {
        if (resp.status === 200) {
          dispatch(getProductList());
          setModal((modal) => ({
            ...modal,
            deleteModal: false,
            deleteData: {},
            editModal: false,
            editData: [],
            prevModal: {},
          }));
          setState((state) => ({ ...state, editSelected: [] }));
        }
      });
    }
  };

  const editMultipleHandle = () => {
    let editData = modal.editData;
    let data = editData.filter((item) =>
      state.editSelected.includes(item.storeId)
    );
    dispatch({ type: Types.PRODUCT_EDIT_GROUPED, payload: data });
    navigate("/product-edit-grouped");
  };

  const checkboxHandle = (id) => {
    let selected = state.editSelected;
    let index = selected.findIndex((o) => o === id);
    if (index >= 0) {
      selected.splice(index, 1);
      setState((state) => ({ ...state, editSelected: [...selected] }));
    } else {
      setState((state) => ({ ...state, editSelected: [...selected, id] }));
    }
  };

  const checkboxUploadHandle = (id) => {
    let selected = state.storeSelected;
    let index = selected.findIndex((o) => o === id);
    if (index >= 0) {
      selected.splice(index, 1);
      setState((state) => ({ ...state, storeSelected: [...selected] }));
    } else {
      setState((state) => ({ ...state, storeSelected: [...selected, id] }));
    }
  };

  const editModal = (data) => {
    setModal((modal) => ({ ...modal, editData: data.values, editModal: true }));
  };

  const uploadFile = () => {
    fileRef.current.click();
  };

  const fileUploadModal = (e) => {
    let files = e.target.files[0];
    if (files.type === "text/csv") {
      setFile((file) => ({ ...file, modal: true, file: files }));
      setState((state) => ({ ...state, clearSearch: true }));
      setTimeout(() => {
        setState((state) => ({ ...state, clearSearch: false }));
      }, 1000);
    } else {
      dispatch({
        type: Types.ALERT_ERROR,
        payload: { message: "Invalid Format. Upload CSV File." },
      });
    }
  };

  const closeFile = () => {
    setFile((file) => ({ ...file, modal: false, file: null }));
  };

  const fileUpload = () => {
    const formData = new FormData();
    const storeIds = state.storeSelected.toString();
    formData.append("file", file.file);
    formData.append("storeIds", storeIds);
    dispatch(productUpload(formData)).then((resp) => {
      if (resp.status === 200) {
        dispatch(getProductList());
        setFile((file) => ({ ...file, modal: false, file: null }));
        setState((state) => ({ ...state, storeSelected: [] }));
      }
    });
  };

  const exportHandle = () => {
    dispatch(productExport()).then((resp) => {
      if (resp.status === 200) {
        //     dispatch(getProductList());
        //     setFile(file => ({ ...file, modal: false, file: null }));
        setExports((exports) => ({
          ...exports,
          isExport: true,
          data: `${resp.data}`,
        }));
      }
    });
  };

  const viewHandle = (data) => {
    setModal((modal) => ({ ...modal, viewData: data, viewModal: true }));
  };

  const viewClose = (data) => {
    setModal((modal) => ({ ...modal, viewData: {}, viewModal: false }));
  };

  const closeCSV = () => {
    setTimeout(() => {
      setExports((exports) => ({ ...exports, isExport: false, data: `` }));
    }, 3000);
  };

  const getStoreName = useCallback(
    (id) => {
      let find = storeData.find((o) => o.storeId === id);
      return find?.storeName;
    },
    [storeData]
  );

  const isImagePushed = () => {
    let isTrue = false;
    state.editSelected?.map((v) => {
      if (state.editedData[v]) {
        isTrue = true;
      }
      return state.editedData[v];
    });
    return isTrue;
  };
  const downloadCSV = () => {
    const csvData = new Blob([exports.data], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = "product_list.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="row d-flex align-items-center mb-3 mb-md-2">
        <div className="col-md-6">
          <p className="medium ft-23 mb-2 mb-md-0">
            {conf.product.list} {loading ? <Loader /> : null}
          </p>
        </div>
        {/* {AdminRoles.includes(userType) ? */}
        <div className="col-md-6 text-start text-md-end">
          {exports.isExport ? (
            <button
              onClick={downloadCSV}
              filename={"product_list.csv"}
              className="btn btn-theme iconBtn"
            >
              <i className="fa fa-download" style={{ paddingTop: "6px" }} />
              <span className="iconText"> Download CSV</span>
            </button>
          ) : null}
          <button
            className="btn iconBtn"
            style={{ backgroundColor: "#b18e2b", color: "white" }}
            onClick={() => uploadFile()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              className="bi bi-upload"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
            </svg>{" "}
            <span className="iconText">Upload</span>
          </button>
          <button
            className="btn iconBtn"
            style={{ backgroundColor: "#779535", color: "white" }}
            onClick={exportHandle}
          >
            {exportLoading ? (
              <Loader />
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  className="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
              </>
            )}
            <span className="iconText"> Download</span>
          </button>
          <Link
            className="btn btn-theme"
            to="/product-add"
            onClick={() => dispatch(Navigation("addProduct"))}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={26}
              height={26}
              fill="currentColor"
              className="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>{" "}
            {conf.product.add}
          </Link>
        </div>
        {/* : null} */}
      </div>
      <div className="row d-flex justify-content-end">
        <DataTable
          key={state.data}
          headers={header}
          data={state.data}
          pagination={true}
          globalSearch={true}
          searchClear={state.clearSearch}
        />
      </div>
      <input
        type="file"
        name="file"
        style={{ display: "none" }}
        ref={fileRef}
        onChange={(e) => fileUploadModal(e)}
        onClick={(e) => (e.target.value = "")}
      />
      {modal.deleteModal ? (
        <CustomModal onclose={closeHandle} size="md">
          <p className="medium ft-23 mb-1">{`Are you Sure to Delete?`}</p>
          <div style={{ textAlign: "left" }}>
            <ol>
              {state.editSelected.length > 0 ? (
                state.editSelected?.map((v, i) => {
                  return <li key={i}>{getStoreName(v)}</li>;
                })
              ) : (
                <span>1. {modal.deleteData.storeName}</span>
              )}
            </ol>
          </div>
          <div className="text-center mb-5 mt-4">
            <span className="btn btn-secondary" onClick={closeHandle}>
              Close
            </span>
            <span
              className={`btn btn-danger`}
              onClick={() =>
                deleteLoad
                  ? null
                  : state.editSelected.length > 0
                    ? deleteMultipleHandle()
                    : deleteHandle()
              }
            >
              {deleteLoad ? (
                <>
                  <Loader /> Loading
                </>
              ) : (
                "Delete"
              )}
            </span>
          </div>
        </CustomModal>
      ) : null}

      {modal.editModal ? (
        <CustomModal onclose={closeHandle} size={"md"}>
          <div style={{ textAlign: "left" }}>
            <ol>
              {modal.editData?.map((v, i) => {
                return (
                  <div key={i}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onClick={() => checkboxHandle(v.storeId)}
                      checked={state.editSelected.includes(v.storeId)}
                    />{" "}
                    {i + 1}. {v.productName} - {v.storeName}{" "}
                  </div>
                );
              })}
            </ol>
          </div>
          {state.editSelected.length > 0 ? (
            <div>
              <span
                className="ms-3 btn-theme btn "
                onClick={() => editMultipleHandle()}
              >
                {" "}
                Edit ({state.editSelected.length})
              </span>
              {!isImagePushed() ? (
                <span
                  className="btn btn-danger"
                  onClick={() => modalHandleCustom()}
                >
                  Delete ({state.editSelected.length})
                </span>
              ) : null}
            </div>
          ) : (
            <span className="btn btn-secondary disabled">
              Select Item to Edit or Delete
            </span>
          )}
        </CustomModal>
      ) : null}

      {file.modal ? (
        <CustomModal onclose={closeFile} size={"lg"}>
          <p className="medium ft-23 mb-1">{`Are you Sure to Upload ${file.file.name}?`}</p>
          <br />
          Select Store's to Upload <br />
          <br />
          <div className="col-8">
            <ol>
              <div className="row">
                {storeData && storeData.length > 0
                  ? storeData?.map((v, i) => {
                    return (
                      <div key={i} className={"col-12"}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onClick={() => checkboxUploadHandle(v.storeId)}
                          checked={state.storeSelected.includes(v.storeId)}
                        />{" "}
                        {i + 1}. {v.storeName}{" "}
                      </div>
                    );
                  })
                  : "No Stores"}
              </div>
            </ol>
          </div>
          <div className="text-center mb-5 mt-4">
            <span className="btn btn-secondary" onClick={closeFile}>
              Close
            </span>
            {state.storeSelected?.length > 0 ? (
              <span className={`btn btn-theme`} onClick={fileUpload}>
                {addLoad ? (
                  <>
                    <Loader /> Loading
                  </>
                ) : (
                  "Submit (" + state.storeSelected.length + ")"
                )}
              </span>
            ) : null}
          </div>
        </CustomModal>
      ) : null}

      {modal.viewModal ? (
        <CustomModal onclose={viewClose} size={"xl"}>
          <div className="row d-flex align-items-center mb-3 mb-md-5">
            <div className="col-md">
              <p
                className="medium ft-23 mb-2 mb-md-0 bold"
                style={{ marginLeft: "2rem" }}
              >
                Product Detail
              </p>
            </div>
            <div className="col-md-6 text-start text-md-end"></div>
          </div>

          <div className={`row d-flex justify-content-center ${modal?.viewData?.values.length > 8 ? "scroll-content" : ""}`} >
            <div className="col-12 col-lg-10">
              <div className="row">
                <ModalList
                  title={conf.product.id}
                  value={modal.viewData?.barCode}
                />
                <ModalList
                  title={conf.product.name}
                  size={12}
                  value={modal.viewData?.productName}
                />
                <ModalList title="Category" value={modal.viewData?.category} />
                <ModalList title={"Quantity"} value={modal.viewData?.quantity} />
                {conf.env == "retail" && <>
                  <ModalList title={"Price"} value={modal.viewData?.price} />
                  <ModalList title={"QR Code URL"} value={modal.viewData?.qrCodeUrl} />
                  <ModalList title={"Discount"} value={modal.viewData?.discount ? modal.viewData.discount + " %" : "0 %"} />
                </>}
                <ModalList
                  title="Description"
                  size={12}
                  value={modal.viewData?.description}
                />
                <ModalList
                  title={conf.product.footNote}
                  size={12}
                  value={modal.viewData?.footnote}
                />
                <div className="d-flex">
                  <div><p class="fw-bold">{conf.product.store}</p></div>
                  <div className="ms-3 text-primary">
                    <ol>
                      {modal?.viewData?.values?.map((v, i) => {
                        return <li key={i}>{v.storeName}</li>;
                      })}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : null}
    </>
  );
}
