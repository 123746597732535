import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Input from '../../components/form/input'
import Select from '../../components/form/select';
import { AdminRoles, RoleOptions } from '../../components/utilities/functions';
import { handleValidation } from '../../components/utilities/validations';
import { UserAdd } from '../../store/users/usersService';
import { Types } from '../../store/types';
import { Loader } from '../../components/utilities/loader';
import { getStoreList } from '../../store/store/storeService';

export default function AddUser() {

    const [state, setState] = useState({ userId: null, firstName: '', lastName: '', userEmail: '', password: '', userType: 'CUSTOMER_ADMIN', store: '' });
    const [value, setValue] = useState({ viewPassword: false, isUpdate: false, storesList: [] });
    const [isAccess, setAccess] = useState(false);
    const [error, setError] = useState({});
    const { addLoad, userData } = useSelector(state => state.usersList);
    const { loading, storeData } = useSelector(state => state.stores);
    const login = useSelector(state => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const userType = login?.userData?.userType;

    useEffect(() => {
        dispatch(getStoreList());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (storeData && storeData.length > 0) {
            const data = storeData.map(v => {
                return {
                    key: v.storeId,
                    name: v.storeName
                }
            });
            setValue(value => ({ ...value, storesList: data }));
        }
    }, [storeData])

    useEffect(() => {
        if (id) {
            let user = userData.find(o => o.userId === id);
            setState(state => ({ ...state, firstName: user?.firstName, lastName: user?.lastName, userEmail: user?.userEmail, password: user?.userPassword, userType: user?.userType, store: user?.storeId }))
            setValue(value => ({ ...value, isUpdate: true }));
        } else {
            setValue(value => ({ ...value, isUpdate: false }));
            setState(state => ({ ...state, firstName: "", userId: null, lastName: "", userEmail: "", password: "", store: "" }))
        }
        setAccess(AdminRoles.includes(userType));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const handleChange = (e) => {
        e.persist()
        setState(state => ({ ...state, [e.target.name]: e.target.value }));
    }
    const handleSubmit = (e) => {
        e?.preventDefault();
        if (isAccess) {
            let conditions = [
                { condition: 'email', name: 'userEmail', value: state.userEmail, text: 'Email' },
                { condition: 'minlength', name: 'password', value: state.password, text: 'Password', min: 8 },
                { condition: 'required', name: 'password', value: state.password, text: 'Password' },
                { condition: 'required', name: 'firstName', value: state.firstName, text: 'First Name' },
                { condition: 'required', name: 'lastName', value: state.lastName, text: 'Last Name' },
                { condition: 'required', name: 'userType', value: state.userType, text: 'Role' },
                { condition: 'required', name: 'userEmail', value: state.userEmail, text: 'Email' },
                { condition: 'required', name: 'store', value: state.store, text: 'Site' },
            ]
            let errors = handleValidation(conditions);

            if (!errors.isError) {
                setError({});
                let data = {
                    email: state.userEmail,
                    password: state.password,
                    firstName: state.firstName,
                    lastName: state.lastName,
                    userType: state.userType,
                    isUserActive: true,
                    storeId: state.store,
                    pkId: id ?? 0,
                    // customerId: 1
                }

                dispatch(UserAdd(data)).then(resp => {
                    if (resp.status === 200) navigate('/users-list');
                });

            } else {
                setError(errors.errors)
            }
        } else {
            dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Your Role has no Access to Add User' } })
        }
    }

    return (
        <>
            <div className="row d-flex align-items-center mb-3 mb-md-5">
                <div className="col-md-6">
                    <p className="medium ft-23 mb-2 mb-md-0">{`${value.isUpdate ? 'Edit' : 'Add'} User`}</p>
                </div>
                <div className="col-md-6 text-start text-md-end">
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-12 col-lg-10">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <Input type='text' value={state.firstName} name='firstName' placeholder='Enter First Name' onchange={handleChange} label={'First Name'} autoFocus={true} error={error?.firstName} required />
                        </div>
                        <div className="col-12 col-md-6">
                            <Input type='text' value={state.lastName} name='lastName' placeholder='Enter Last Name' onchange={handleChange} label={'Last Name'} error={error?.lastName} required />
                        </div>
                        <div className={value.isUpdate ? "col-12" : "col-12 col-md-6"}>
                            <Input type='text' value={state.userEmail} name='userEmail' placeholder='Enter Email Address' onchange={handleChange} label={'User Email'} error={error?.userEmail} required />
                        </div>
                        {value.isUpdate ? null :
                            <div className="col-12 col-md-6">
                                <Input type={value.viewPassword ? 'text' : 'password'} value={state.password} name='password' placeholder='Enter Password' onchange={handleChange} label={'Password'} error={error?.password} required />
                                <span className='view-password' onClick={() => setValue(value => ({ ...value, viewPassword: !value.viewPassword }))}>{value.viewPassword ? 'Hide Password' : 'View Password'}</span>
                            </div>}
                        <div className="col-12 col-md-6">
                            <Select name='userType' value={state.userType} label={'Role'} onchange={handleChange} options={RoleOptions} error={error?.userType} required />
                        </div>
                        <div className="col-12 col-md-6">
                            <Select name='store' value={state.store} label={'Site'} onchange={handleChange} options={value.storesList} error={error?.store} required loading={loading} />
                            {/* <Input type='text' value={state.store} name='store' placeholder='Enter Store' onchange={handleChange} label={'Store'} /> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-center mt-4 mb-5">
                            <button className={`btn ${!isAccess ? "btn-secondary" : value.isUpdate ? 'btn-warning' : 'btn-theme'}`} onClick={(e) => addLoad || !isAccess ? null : handleSubmit(e)}> {addLoad ? <Loader /> : <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>} {!isAccess ? "No Access to Add a User" : addLoad ? "Loading" : value.isUpdate ? "Update User" : "Add User"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
