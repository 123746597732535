import { NavLink } from 'react-router-dom';


const handleClick = (e) => {
  e.preventDefault()
}

const Tabs = () => (
  <ul className="nav nav-tabs">
    <li className="nav-item">
      <NavLink to='/basestation'
        className={({ isActive, isPending }) =>
          isPending ? "pending" : isActive ? "nav-link active" : "nav-link"
        }
        end
      >BaseStation List</NavLink>
    </li>
    <li className="nav-item">
      <NavLink to='/basestation/tags'
        onClick={handleClick}
        className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "nav-link active" : "nav-link"}
      >
        Tags List
      </NavLink>

    </li>
  </ul>
);
export default Tabs;