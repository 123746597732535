import axios from "axios";
import { baseUrl } from '../API';
import { Types } from "../types";

export const ClearAlert = () => {
    return (dispatch) => {
        dispatch({ type: Types.ALERT_CLEAR });
    }
};

export const Navigation = (menu) => {
    return (dispatch) => {
        dispatch({ type: Types.NAVIGATION_LABEL, payload: menu });
    }
};

export const DashboardInfo = () => {
    return (dispatch) => {
        dispatch({ type: Types.DASHBOARD_REQUEST });
        return axios.get(baseUrl + '/cpe/getDashboardInfo').then(resp => {
            if (resp.status === 200) {
                dispatch({ type: Types.DASHBOARD_REQUEST_SUCCESS, payload: resp.data });
            } else {
                dispatch({ type: Types.DASHBOARD_REQUEST_ERROR });
            }
            return resp;
        }).catch(err => {
            dispatch({ type: Types.DASHBOARD_REQUEST_ERROR });
            // if (err.response?.data?.error) {
            //     dispatch({ type: Types.ALERT_ERROR, payload: { message: err.response?.data?.error } });
            // } else {
            //     dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Product Upload Failed' } });
            // }
            return err;
        });
    }
};
export const POSTagList = () => {
    return () => {
        return axios.get(baseUrl + '/cpe/catalogList').then(resp => {
            if (resp.status === 200) {
                return resp
            }
        }).catch(err => {
            return err;
        })
    }
}
export const saveSquarePOS = (data) => {
    return (dispatch) => {
        return axios.post(baseUrl + '/cpe/saveAppIdAndSecret', data).then(resp => {
            if (resp.status === 200 || resp.status === 201)
                dispatch({ type: Types.ALERT_SUCCESS, payload: { message: 'Saved Successfully' } });
            return resp;
        }).catch(err => {
            return err;
        });
    }
}
